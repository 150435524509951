<template>
    <!--<v-footer app
              absolute
              height="auto"
              color="#fff">-->
    <div class="v-footer">
        <v-container>
            <div class="footer-box hidden-sm-and-down" v-if="showLi">
                <!--<div></div>-->
                <div class="footer-nav">
                    <ul>
                        <li class="nav-item" v-for="(nav,index) in copyNavList" :key="index">

                            <div class="email mb-10 mt-n1 d-flex flex-column" v-if="false">
                                <!--v-if="index==copyNavList.length-1"-->
                                <div>邮件订阅</div>
                                <span class="mt-2 mb-5">第一时间收到我们的最新产品资料</span>
                                <input class="pa-1"/>
                            </div>

                            <router-link :to="nav.to" v-if="nav.to">{{$i18n.t(nav.key)}}</router-link>
                            <router-link to="" v-else>{{$i18n.t(nav.key)}}</router-link>
                            <ul class="sub-nav-list" v-if="nav.items">
                                <li class="sub-nav-item" v-for="(item,i) in nav.items" :key="i">
                                    <a :href="item.href" target="_blank" v-if="item.href">{{$i18n.t(item.key)}}</a>
                                    <router-link :to="item.to" v-else>{{$i18n.t(item.key)}}</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="follow d-flex flex-column align-center justify-center pt-md-10 pb-md-5">
                <!--<div>
                    {{$t('common.follow')}}
                </div>-->
                <div class="d-flex flex-row">
                    <div class="item d-flex flex-column">
                        <a class="iconfont iconweixin1" style="color: #2DB51A;"></a>
                        <div class="black--text text text-center hidden-md-and-up">{{$t('common.wechat')}}</div>
                        <div class="qrcode">
                            <div class="qrcode1">
                                <v-img contain
                                       src="http://website-asc.oss-cn-shanghai.aliyuncs.com/index/code_finewines.jpg"></v-img>
                                官方公众号
                                <!--ASC wechat offical public account-->
                            </div>
                            <div class="qrcode1">
                                <v-img contain
                                       src="http://website-asc.oss-cn-shanghai.aliyuncs.com/index/code_yijiu.jpg"></v-img>
                                蓄谋一酒
                            </div>

                        </div>
                    </div>
                    <div class="ml-4 mr-4 ml-md-8 mr-md-8 d-flex flex-column">
                        <a href="http://weibo.com/ascfinewines" target="_blank" class="iconfont iconweibo1"
                           style="color: #FF4141;"></a>
                        <div class="black--text text text-center hidden-md-and-up">{{$t('common.weibo')}}</div>
                    </div>
                    <div class="d-flex flex-column">
                        <a target="_blank" class="iconfont iconlingying" style="color: #0071E0;"></a>
                        <div class="black--text text text-center hidden-md-and-up">{{$t('common.linkedin')}}</div>
                    </div>
                </div>
                <div class="d-md-none d-flex flex-column align-center mt-7 mb-6">
                    <div>客户服务热线</div>
                    <div class="mt-1 phone">400-883-2299</div>
                    <div class="email mt-9 text-center" v-if="false">
                        <div>邮件订阅</div>
                        <div class="mt-2 mb-3 text">第一时间收到我们的最新产品资料</div>
                        <input class="pa-1"/>
                    </div>
                </div>
            </div>
            <div class="text-center copyright body-2">
                <a target="_blank" href="https://beian.miit.gov.cn/">© 2023 {{$t('common.app_title')}}
                    {{copyright}}</a>
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
<!--                    < img src="" style="float:left;"/>-->
                    <p style="margin-left: 10px">沪公网安备 31010802001506号</p >
                </a >
            </div>
        </v-container>
    </div>
    <!--</v-footer>-->
</template>

<script>
    import data from '@/config/nav'
    export default {
        name: "app-footer",
        data() {
            return {
                copyright: '沪ICP备05040324号-1',
                copyNavList: JSON.parse(JSON.stringify(data.navList))
            }
        },
        props: ['showLi'],
        created(){
            this.$set(this.copyNavList, 0, {
                key: '',
                to: '',
                name: '客服服务热线',
                items: [{
                    key: '',
                    to: '',
                    name: '400-883-2299',
                }]
            })
        }
    }
</script>

<style lang="scss" scoped>
    .copyright {
        a {
            color: $secondary-color;
            text-decoration: none;
        }
    }

    .v-footer {
        border-top: 7px solid #B78E55 !important;
        background-color: white;
    }

    .footer-box {
        padding-top: 50px;
    }

    .footer-nav {
        flex: 1;
        > ul {
            display: flex;
            justify-content: space-between;
        }
    }

    .nav-item {
        position: relative;
        > a {
            position: relative;
            font-size: 18px;
            line-height: 1;
        }
    }

    .sub-nav-list {
        text-align: left;
        padding: 10px 0;
        transition: all 0.5s 0.3s ease;
        z-index: 99;
        .sub-nav-item {
            margin-bottom: 10px;
            a {
                display: inline-block;
                position: relative;
                color: #7E7E7E;
                font-size: 14px;
                font-weight: 300;
                line-height: 1;
                transition: all .25s;
                text-decoration: none;
                &:hover {
                    transform: translateX(10px) translateZ(0);
                    &:before {
                        width: 100%;
                        left: 0;
                    }
                    .code-img {
                        opacity: 1;
                    }
                }
                &:before {
                    content: "";
                    position: absolute;
                    width: 0;
                    left: 100%;
                    height: 1px;
                    bottom: -10px;
                    transition: transform .25s;
                }
            }
        }
    }

    .follow {
        color: $primary-color;
        /*font-size: 22px;*/
        a {
            cursor: pointer;
            font-size: 50px;
            text-align: center;
        }
        .item {
            position: relative;
            .qrcode {
                @include lb();
                position: absolute;
                left: -70%;
                bottom: calc(90%);
                width: 120px !important;
                visibility: hidden;
                display: flex;
                opacity: 0;
                transition: all 0.5s 0.3s ease;
                .qrcode1{
                    width: 120px !important;
                    text-align: center;
                    color: #7E7E7E;
                    background-color: white;
                }
            }
            &:hover {
                .qrcode {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .email {
        div {
            color: $primary-color;
            font-size: 22px;
        }
        span {
            font-size: 16px;
            color: #7E7E7E;
            font-weight: 300;
        }
        input {
            outline-style: none;
            width: 240px;
            color: $primary-color;
            border: 1px solid #7E7E7E;
        }
    }

    @media (max-width: $screen-md) {
        .follow {
            font-size: 16px;
            a {
                font-size: 20px;
            }
            .item {
                .qrcode {
                    bottom: calc(95%);
                    width: 100px !important;
                }
            }
            .text {
                font-size: 9px;
            }
        }
        .phone {
            color: #7E7E7E;
        }
        .email {
            div {
                font-size: 16px;
            }
            .text {
                font-size: 10px;
                color: #7E7E7E;
            }
        }
        .copyright {
            a {
                font-size: 8px !important;
            }
        }
    }

    @media (max-width: $screen-sm) {
        .follow {
            font-size: 14px;
            span {
                font-size: 16px;
            }
        }
        .email {
            div {
                font-size: 14px;
            }
        }
    }
</style>
