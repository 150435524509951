/**
 * Created by Elvira on 2020/5/8.
 */
export default {
    history: {
        title: '发展历程',
    },
    beer: {
        title: '三得利万志啤酒',
        details: "THE PREMIUM MALT'S：高雅的清香、浓郁甘醇，饮后余韵无穷。追求优质啤酒的梦想，从原材料到工艺制法，始终\'执着\'追求，精心制造。THE PREMIUM MALT'S在2005、2006、2007年的世界品质评鉴大会上连续3年荣获啤酒类特别金奖。"
    },
    wineries: {
        details1: {
            bannerImg: 'product/wineries/bg_1.jpg',
            logo: 'index/logo_1.jpg',
            title: '露纹酒庄',
            enTitle: 'Leeuwin Estate',
            country: '澳大利亚',
            linkUrl: 'www.leeuwinestate.com.au',
            desc: '美国纳帕谷代表人物 Robert Mondavi，在1972年大面积考察最适合在澳大利亚最适宜酿造顶级品质单一品种葡萄酒的地区时，发现了露纹葡萄园。在Mondavi早期指导下，Denis 和 Tricia Horgan开始了他们的奶牛场的改造，并于1973建立了露纹酒园。露纹建立时立志：“追求卓越以生产世界上最好的葡萄酒”。1972年，露纹酒园开始培育其苗圃，并从1973开始，开始投入生产的葡萄园经过五年以上的人工种植。艺术风格的酒园大楼于1978年开放，第一个商业年份是在1979年。注重品质大于产量，露纹酒园一致保持低产量，该庄园的产量仅占澳大利亚葡萄酒的0.06%。享受一个孤立的、原始的自然环境，作为高度熟练的酿酒师团队，露纹的葡萄种植实践的目的是低干预，确保其佳酿跻身于世界美酒之列。',
            img: 'product/wineries/img_1.jpg',
        },
        details2: {
            bannerImg: 'product/wineries/bg_2.jpg',
            logo: 'index/logo_2.jpg',
            title: '翰斯科酒庄',
            enTitle: 'HENSCHKE',
            country: '澳大利亚',
            linkUrl: 'www.henschke.com.au',
            desc: '1862年Johann Christian Henschke 在位于凯尼顿（Keyneton ）的种植着多种作物的农田中亲手开辟了一小片葡萄园，翰斯科家族的酿酒历史由此开始。和许多西里西亚人一样，Johann 为了获得宗教自由，离开了自己的故乡。1841年，Johann 来到Kutschlau。Johann 酿造的葡萄酒最初只用来款待家人和朋友，但1868年酒庄开始尝试商业化以后，历史的车轮注定将赐予它更大的成就。翰斯科家族历史悠久的酒庄和住宅均建于1861年，取材自从自家宅地挖掘的沉积岩，象征着家族历经多年的辛勤劳作以及对这篇土地的热爱。近年来，随着勾兑酒和强化成分葡萄酒的风靡，家族第四代掌门人Cyril Henschke 开创了品种酒和单一葡萄园葡萄酒并行的先河。1950年开辟的神恩山（Hill of Grace）和宝石山（Mount Edelstone ）是Cyril拥有的宝贵遗产，而来自伊顿谷（Eden Valley ）的西拉红葡萄酒已经满足了红酒业的最高期望。',
            img: 'product/wineries/img_2.jpg',
        },
        details3: {
            bannerImg: 'product/wineries/bg_3.jpg',
            logo: 'index/logo_3.jpg',
            title: '穆加酒庄',
            enTitle: 'Bodegas Muga',
            country: '西班牙',
            linkUrl: '',
            desc: '穆加酒庄（Bodegas Muga）位于西班牙里奥哈（Rioja）葡萄酒产区，是该地区 知名的酒庄之一，一直由穆加家族（Muga Family）经营和管理。' +
            '<br><br>穆加酒庄坐落于历史悠久的里奥哈哈罗火车站小区（Barrio de la Estacion de Haro）内，该小区是里奥哈地区众所周知的酒庄区，因靠近哈罗火车站，故 而取意于此。穆加酒庄建在一座已有 200 年历史的古楼里，整个酒庄就如同橡 木世界一般，除了建筑本身是由石头和橡木头堆砌而成的以外，酒庄内还存放 了 14,000 个不同容量且由不同国家生产的橡木桶。酒庄内设有独立的品酒室， 可以举行各种团体活动，游客来到这里不仅可以品尝到美味优质的葡萄酒，还 可以参观酒庄酿造葡萄酒的具体过程，体验酒庄独有的葡萄酒文化。' +
            '<br><br>穆加酒庄的葡萄园位于里奥哈的上里奥哈（Rioja Alta）产区，享有独特的风 土条件，不但有优越的地理位置和良好的朝向，同时还受到地中海气候、大西 洋气候和大陆性气候的多重影响。园中的土壤主要是由富含石灰质的粘土组 成，这里出产的葡萄成熟期更长，风味物质更浓郁。穆加酒庄的总面积为 200 公顷，其中葡萄园占地 150 公顷，主要种植着丹魄（Tempranillo）、歌海娜 （Garnacha）、马士罗（Mazuelo）和格拉西亚诺（Graciano）等红葡萄品种， 以及维奥娜（Viura）和玛尔维萨（Malvasia）等白葡萄品种。其中，丹魄葡萄 是酒庄所有红葡萄酒的基酒。酒庄根据不同的土壤特性，把葡萄园划分成若干 个独立的葡萄种植区，并加以分别管理，以期获得能够完美体现出各品种特色 的优质葡萄。' +
            '<br><br>穆加酒庄始终坚持以可持续发展的方式来管理葡萄园，结合着世界先进的酿酒 设备和传统精湛的酿酒技术，一直致力于出产具有里奥哈风土特色的优质葡萄 酒。酒庄出产的酒款种类繁多，现已发布的经典酒款有 Blanco、Rosado、 Eneas、Crianza、Seleccion Especial、Prado Enea、Torre Muga、Aro、Cava Conde de Haro 和 Cava Conde de Haro Rose，深受广大消费者的喜爱。 ',
            img: 'product/wineries/img_3.jpg',
        },
        details4: {
            bannerImg: 'product/wineries/bg_4.jpg',
            logo: 'index/logo_4.jpg',
            title: '三得利登美之丘酒庄',
            enTitle: 'Tomi Ni Oka',
            country: '日本',
            linkUrl: '',
            desc: '三得利登美之丘酒庄，位于日本中部的山梨县甲府市，俯瞰甲府盆地可以将酒庄周围的秀美景色尽收眼底，同时也可以远眺位于南面著名的富士山，美不胜收。而“登美之丘”的名字也来源于此。这个田园般的产区给葡萄种植赋予了得天独厚的自然条件。酒庄自建立以来，拥有着超过100 年的酿造经验。每一年，酒庄都秉承着他们对于品质，传统和创新一如既往的追求，专注在葡萄栽培，种植以及酿造工艺上，从而打造出精美绝伦的佳酿。',
            img: 'product/wineries/img_4.jpg',
            items: [{
                title: '',
                desc: '',
                img: 'product/wineries/award_4_1.jpg'
            }, {
                title: '',
                desc: '',
                img: 'product/wineries/award_4_2.jpg'
            }, {
                title: '',
                desc: '',
                img: 'product/wineries/award_4_3.jpg'
            }, {
                title: '',
                desc: '',
                img: 'product/wineries/award_4_4.jpg'
            }]
        },
        details5: {
            bannerImg: 'product/wineries/bg_5.jpg',
            logo: 'index/logo_5.jpg',
            title: '玛希庄园 / 瓦尔普利切拉',
            enTitle: 'MASI / VALPOLICELA',
            country: '意大利',
            linkUrl: 'www.masi.it',
            desc: "玛希庄园创建于18 世纪末，连续六代由博萨尼（ Boscaini ）家族掌管。玛希庄园以其时尚、愉悦、平衡感甚佳且独树一帜的葡萄酒赢得了“ 维纳图革命性的酿酒艺术” 称号。Hugh Johnson 称之为“ 维罗图酿酒的基准” 。",
            img: 'product/wineries/img_5.jpg',
        },
        details6: {
            bannerImg: 'product/wineries/bg_6.jpg',
            logo: 'index/logo_6.jpg',
            title: '御兰堡酒庄',
            enTitle: 'Yalumba',
            country: '澳大利亚',
            linkUrl: '',
            desc: '御兰堡始创于1849年，其创立者塞缪尔•史密斯原是一名英国啤酒酿酒师。举家移民至澳大利亚后，史密斯父子在安格斯顿东南边购置了一块30英亩的土地，在月光中种下了他们第一株葡萄树。塞缪尔把这片土地称为“Yalumba御兰堡”，在土著语里意为“周围所有的土地”。时逾160年，在五代人的努力下，御兰堡这个澳大利亚古老的家族酒庄已具备相当的规模和声誉。其成功演变为澳大利亚葡萄酒行业中的传奇故事，更为全世界葡萄酒酿造者所称羡。坚持可持续发展的原则，并以其苗圃和老藤为傲，御兰堡更是全世界为数不多自己制造木桶的酒庄之一。',
            img: 'product/wineries/img_6.jpg',
        },
        details7: {
            bannerImg: 'product/wineries/bg_7.jpg',
            logo: 'index/logo_7.jpg',
            title: '嘉雅 / 托斯卡纳',
            enTitle: 'GAJA / TUSCANY',
            country: '意大利',
            linkUrl: '',
            desc: "嘉雅位于托斯卡纳(Tuscany) 贝格瑞(Bolgheri) 及蒙塔尔奇诺(Montalcino)地区的歌玛达果园(Ca’Marcanda) 和桑塔瑞缇塔教区(Pieve Santa Restituta) ，为您奉上更多广受好评的葡萄酒，包括单一葡萄园蒙塔尔奇诺布鲁诺和特级托斯卡纳葡萄酒。<br><br>“ 在近50 年中，没有任何一家酒园能给国际葡萄酒界带来如此深远的影响！ ” – 《葡萄酒鉴赏家》",
            img: 'product/wineries/img_7.jpg',
        },
        details8: {
            bannerImg: 'product/wineries/bg_8.jpg',
            logo: 'index/logo_8.jpg',
            title: '班菲酒厂 / 托斯卡纳',
            enTitle: 'CASTELLO BANFI / TUSCANY',
            country: '意大利',
            linkUrl: 'www.castellobanfi.com/en/home',
            desc: "玛瑞安妮家族在1978 年建立了卡斯特罗· 班菲酒庄，酒庄总面积有7,100 英亩。班菲酒园在意大利酒庄中的独树一帜不仅仅因为它的面积，因为它由单一葡萄园组成，因为它的艺术酒庄之称。而且因为它的发展只有不到短短30 年。" +
            "<br><br>葡萄酒热心家杂志将其评为2003 年年度酒庄之称。葡萄酒鉴赏家杂志在三年内两度将蒙塔尔奇诺布鲁诺和小金山蒙塔尔奇诺布鲁诺评为年度10大葡萄酒。" +
            "<br><br>“ 葡萄酒庄有很多，于是，那些凭借自身的力量提高整个产区所有酒园水准，成为该产区标志性的酒商就成为该地区的超级酒庄。班菲酒庄就是蒙塔尔奇诺地区的超级酒庄。”- Monica Larner ，葡萄酒热心家，2006 年4 月",
            img: 'product/wineries/img_8.jpg',
        },
        details9: {
            bannerImg: 'product/wineries/bg_9.jpg',
            logo: 'index/logo_9.jpg',
            title: '布琅兄弟',
            enTitle: 'BROWN BROTHERS',
            country: '澳大利亚',
            linkUrl: '',
            desc: 'Founded in 1889 by John Francis Brown, Brown Brothers is one of Australia’s leading family-owned wine companies, with the family making wine in Victoria for over 130 years. For 130 years the Brown Family’s sense of adventure and innovation has led us to create new wine varieties, push boundaries and develop vineyards in some of the best regions in Australia. We have evolved from a single brand to a family of brands that now includes Brown Brothers, Devil’s Corner, Tamar Ridge, Pirie and Innocent Bystander. From the first vintage in Milawa, in Victoria’s King Valley in 1889, to the multifaceted business we are today, we pride ourselves on our family heritage, our connection to consumers whilst being a valuable partner to our customers. The third and fourth generations of the family currently are custodians of the business. Family keeps the innovation tradition alive with over 60 different wines available for tasting.',
            img: 'product/wineries/img_9.jpg',
        },
        details10: {
            bannerImg: 'product/wineries/bg_10.jpg',
            logo: 'index/logo_10.jpg',
            title: '怡园酒庄',
            enTitle: 'Grace Vineyard',
            country: '中国',
            linkUrl: 'www.grace-vineyard.com',
            desc: '1997年8月，在法国葡萄酒学者 Denis Boubals 教授的协助下，陈进强先生和来自法国的詹威尔先生在山西省太原市以南40公里的太谷县，创办并取名“怡园”，意喻“心旷神怡的花园”，希望与朋友把这里视作一片宁静的乐土,一起享受美酒。秉承了“只有家族才能作长线计划，一代接一代地孕育出好酒”的理念，2002年，陈芳女士（Judy Chan)接手怡园酒庄。在她的管理下，"怡园酒庄已经成为中国最受肯定的葡萄酒庄，被誉为中国精品酒庄的标杆品牌，"获得了国际葡萄酒界的广泛好评，其中包括著名的酒评家杰西斯•罗宾逊（Jancis Robinson)、詹姆斯•哈莱迪（James Halliday)以及《葡萄酒观察家》、《品醇客》等国际权威专业杂志。',
            img: 'product/wineries/img_10.jpg',
        },
        details11: {
            bannerImg: 'product/wineries/bg_11.jpg',
            logo: 'index/logo_11.jpg',
            title: '普西河谷/ 伊甸谷',
            enTitle: 'Pewsey Vale Vineyard/ Eden Valley',
            country: '澳大利亚',
            linkUrl: 'www.pewseyvale.com',
            desc: '1847年，刚刚离开英国来到澳大利亚的乔瑟夫古博先生在伊甸谷发现了一块非常适合种植葡萄的土地，' +
            '于是在此种植了这个地区的第一块葡萄田。这些葡萄田的种植可以追溯到詹姆斯柏思碧先生最初带来澳大利亚' +
            '的葡萄苗。就像被隐藏起来的乌托邦一样，20世纪60年代，御兰堡家族重新投入修整了葡萄园，并把注意力' +
            '集中在美好而又多样的雷司令的开发中。 <br>一个葡萄园。一个葡萄品种。一个愿景。就是这样普西河谷' +
            '薏丝琳成为了深受青睐的澳大利亚雷司令。1847年，刚刚离开英国来到澳大利亚的乔瑟夫古博先生在伊甸谷' +
            '发现了一块非常适合种植葡萄的土地，于是在此种植了这个地区的第一块葡萄田。这些葡萄田的种植可以追溯到' +
            '詹姆斯柏思碧先生最初带来澳大利亚的葡萄苗。就像被隐藏起来的乌托邦一样，20世纪60年代，御兰堡家族重新' +
            '投入修整了葡萄园，并把注意力集中在美好而又多样的雷司令的开发中。 <br>一个葡萄园。一个葡萄品种。' +
            '一个愿景。就是这样普西河谷薏丝琳成为了深受青睐的澳大利亚雷司令。',
            img: 'product/wineries/img_11.jpg',
        }
    },
    water: {

        // desc1: '翡莱利含气天然矿泉水源于意大利南部里卡多地区（ Riardo ）（距那不勒斯约60公里）。<br>里卡多的罗卡蒙菲纳火山上的雨水缓慢流经地下岩石层，历时长达30多年，造就了如今的翡莱利水。<br>流经岩石层的雨水富含多种天然矿物质，产生细腻的天然气泡；此外，水源中还有天然二氧化碳，这使得翡莱利水拥有天然细腻的气泡和别具一格的口感。'
        items: [{
            logoName: '依云天然矿泉水',
            desc: '依云的品牌介绍已无需赘言，它是家喻户晓的矿泉水品牌。依云天然矿泉水源自法国阿尔卑斯山，' +
            '历经多年的天然过滤和冰川砂层的矿化，天然纯净，远离污染。依云品牌自1986年进入中国市场，一直' +
            '锁定主要城市的高端受众，为中国消费者传达崭新的自然、健康、年轻、时尚的生活理念，使“活出年轻”' +
            '的品牌精神深入人心。',
        }, {
            logoName: '波多含气天然矿泉水',
            desc: '波多是深受法国人民喜爱的天然矿泉水品牌。两个世纪以来，波多一直被认为是品鉴法式美食、美酒佐餐用水之尚选，赢得知名厨师和侍酒师的一致推荐。作为波多含气天然矿泉水的长期推崇者，罗伯特•帕克对这款含气天然矿泉水，如此评价： “我选择波多作为品酒时的搭配饮水，已经超过10年了。我认为它和葡萄酒十分配合， 它精细的天然气泡，能够更加凸显每一款葡萄酒独特的味道 。”如今在中国高端餐饮业，波多正快速成为气泡矿泉水中的闪亮明星。'
        }, {
            logoName: 'VOSS芙丝矿泉水',
            desc: '源自挪威的VOSS芙丝矿泉水被视为矿泉水中的上品，水中溶解矿物质量(TDS)只有44mg/l。顺滑的口感非常适合搭配精美的菜肴，VOSS常被名厨和葡萄酒爱好者认为是搭配美食美酒理想选择！同时亦深受高级的餐厅、酒吧、会所爱好。TDS的全写是「Total Dissolved Solids」，中文意思是溶解于水中的固体总含量，也简称为「总固体含量」。TDS值代表了水中溶解物杂质的含量。当TDS值越小，水中的杂质含量便越小。Voss由ASC于香港境内经销。'
        }, {
            logoName: '翡莱利含气天然矿泉水',
            desc: '翡莱利含气天然矿泉水源于意大利南部里卡多地区， 距那不勒斯约6 0 公里。水源地位于意大利一火山， 带有活力与激情的特性。火山上的雨水缓慢流经地下岩石层， 历时长达30 多年， 在此过程中雨水经层层过滤， 深入地下， 集多种矿物质和天然二氧化碳， 因而翡莱利成分天然， 气泡细腻。'
        }]
    },
    news: {
        title: '资讯中心',
        list: [{
            img: 'company/news/img_one.jpg',
            title: '抗击疫情，三得利与ASC在行动',
            subTitle: '2月25日，三得利株式会社社长新浪刚史与中国驻日本大使孔铉佑会晤。新浪社长代表三得利，通过中国驻日使馆，为中国抗击新冠肺炎疫情捐赠5,000万日元。',
            pubTime: '2020-02-28'
        }, {
            img: 'company/news/img_two.jpg',
            title: '邂逅依云 法式浪漫下午茶',
            subTitle: '依云矿泉水2019设计师限量瓶与Joel Robuchon 下午茶精选套餐上线<br>2019年6月1日至7月30日，来自法国的高端饮用水品牌依云将与沪上法餐标杆Joel Robuchon 携手。',
            pubTime: '2020-02-28'
        }, {
            img: 'company/news/img_three.jpg',
            title: 'ASC精品酒业与拉菲罗斯柴尔德集团开启合作新篇章',
            subTitle: '大中华地区领先的精品葡萄酒进口及经销商，ASC精品酒业今日宣布了与拉菲罗斯柴尔德集团（DBR (Lafite)）达成的一项新协议。',
            pubTime: '2020-02-28'
        }, {
            img: 'company/news/img_four.jpg',
            title: 'ASC精品酒业宣布成为三得利集团旗下登美之丘酒···',
            subTitle: '（中国，上海–2018年9月26日）作为中国精品葡萄酒行业中的领军企业，ASC精品酒业非常高兴的宣布自2018年10月29日起，将成为日本登美之丘酒庄在中国大陆地区的独家进口商与代理商。',
            pubTime: '2020-02-28'
        }]
    },
    events: {
        typeList: [{id: 1, title: '所有活动', type: 'all'}, {id: 2, title: '派对', type: 'party'}, {
            id: 3,
            title: '品鉴会',
            type: 'wineTasting'
        }, {
            id: 4,
            title: '晚宴',
            type: 'wineDinner'
        }, {id: 5, title: '店铺活动', type: 'shopActivity'}, {id: 6, title: 'ICON EVENTS', type: 'iconEvents'}],
    },
    employee: {
        banner: {
            title: '我们的员工'
        },
        desc: "在ASC我们有着坦诚、友好、高度合作的工作环境，为此我们感到万分自豪。我们团结合一，为实现我们的愿景和使命，共同奋斗。",
        title: "让我们聆听ASC员工的心声：",
        join: {
            title: '招贤纳士',
            schoolTitle: '校园招聘',
            socialTitle: '社会招聘',
            otherBanner: {
                title: '加入ASC，在你的工作中，',
                enTitle: '每一天，每一个地方庆祝生活。'
            }
        }
    },
    product:{
        banner: {
            title: '葡萄酒'
        },
    }
}
