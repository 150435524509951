/**
 * Created by Elvira on 2020/5/13.
 */
export default {
    auction: {
        title:'ASC AUCTION HOUSE',
        enTitle:'ASC AUCTION HOUSE',
        tip: 'Besides En Primeur, wine auction is also a part of our renowned fine wine service.',
        desc: 'In order to meet the fast growing demand for fine wines in the region, ASC Fine Wines launched its fine wine auction division – ASC Auction Co., Ltd – in November 2010 thus becoming the first licensed professional wine auction house in China.' +
        '<br><br>The establishment of ASC Auction Co. matches the growing demand for fine wines in China by providing access to the most sought after wines of the world through a new platform.' +
        '<br><br>In addition to being the first fine wine auction house in China, ASC’s fine wine auctions excel because of the unique customer and market oriented service it provides.'
    },
    list: [{
        title: 'Chateau Direct',
        subTitle: 'Authentic provenance guaranteed at a competitive price',
        desc: 'Unlike most wine auction houses that source their auction lots from individual collectors, our longstanding partnerships with the world’s top Chateaux allows ASC Auction Co. to source directly from producers. In this way we can guarantee the authenticity, quality and competitive price of the products.'
    }, {
        title: 'Excellent service',
        subTitle: 'Multiple options for delivery destination',
        desc: 'First and foremost, ASC is a professional wine importer based in China, so we can provide better services than other auction houses and bring the wines that customers buy in the auction into China. There is therefore no need to worry about the difficulties of complicated logistics and customs procedures.However, if our customers wish to receive their wine in Hong Kong or Bordeaux, we can also provide duty unpaid offers for them to bid.'
    }, {
        title: 'Preferential commission system',
        subTitle: 'Preferential commission system to give more savings to buyers ',
        desc: 'ASC Auction Company has a very preferential commission system that offers more savings to buyers. Several auctions are held very year enabling those interested in wine, an opportunity to acquire allocated Bordeaux Grand Cru Classes wines as well as rare wines from world renowned fine wine producers such as Gaja, E.Guigal, Leroy, Shafer, Joseph Phelps, each of which with clear provenance directly from the owners cellars.'
    }],
    desc: 'For more information about ASC Auction Co. Ltd or our upcoming auctions, please contact our Fine Wine Division for further information: auctioninquiry@asc-wines.com'
}