export default {
    numberList: [{
        num: 27,
        unit: '年',
        text: '在中国',
        to:'/company/history'
    }, {
        num: 100,
        unit: '+家',
        text: '知名酒庄',
        to:'/product/acting'
    }, {
        num: 16,
        unit: '个',
        text: '国家',
        to:'/product/acting'
    }, {
        num: 1000,
        unit: '+款',
        text: '葡萄酒',
        to:'/product/acting'
    }],
    about: {
        img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/about_img.jpg',
        title: '关于我们',
        enTitle: 'ABOUT US',
        desc: 'ASC精品酒业在扎根中国的二十六载高瞻远瞩，成为中国进口葡萄酒行业的知名企业，ASC的斐然成绩源于其优异的产品质量，多年丰富的业务经验，全备的产品种类，员工们专业的服务，以及一贯秉承的顾客至上的企业宗旨。',
        to: '/company/about'
    },
    product: {
        img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/about_img.jpg',
        title: '我们的产品',
        enTitle: 'OUR PORTFOLIO',
        desc: '正如公司名称所示，ASC精品酒业致力于为中国消费者提供精品葡萄酒专业方案。ASC精品酒业独家进口及经销来自15个国家、100多个酒庄的1000多种精品佳酿，为消费者提供广泛的选择和上乘的品质。这些酒庄包括了大型跨国葡萄酒酿造集团，同时不乏传奇的葡萄酒世家。ASC与这些酒庄拥有长期且紧密的战略合作伙伴关系。',
        to: '/product/acting',
        items: []
    },
    people:{
        desc:'在ASC我们有着坦诚、友好、高度合作的工作环境，为此我们感到万分自豪。我们团结合一，为实现我们的愿景和使命，共同奋斗。'
    },
    items: [{
        iconfont: 'iconjingyan',
        title: '经验'
    }, {
        iconfont: 'iconzhenyuan',
        title: '臻源'
    }, {
        iconfont: 'iconfuwu',
        title: '服务'
    }]
}
