export default {
    numberList: [{
        num: 27,
        unit: '',
        text: 'YEARS IN CHINA',
        to:'/company/history'
    }, {
        num: 100,
        unit: '+',
        text: 'WINERIES',
        to:'/product/acting'
    }, {
        num: 16,
        text: 'COUNTRIES',
        to:'/product/acting'
    }, {
        num: 1000,
        unit: '+',
        text: 'WINES',
        to:'/product/acting'
    }],
    about: {
        img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/about_img.jpg',
        title: '关于我们',
        enTitle: 'ABOUT US',
        desc: 'ASC Fine Wines has shown great foresight concerning the changing shape of the wine industry in China. It has been able to consolidate its position as the finest wine importing business in China over the past 26 years, in part thanks to ASC’s superior portfolio, substantial importing experience and outstanding customer service.',
        to: '/company/about'
    },
    product: {
        img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/about_img.jpg',
        title: '我们的产品',
        enTitle: 'OUR PORTFOLIO',
        desc: 'As the corporate name suggests, ASC Fine Wines is a specialist in purveying some of the finest wines of the world. ASC represents more than 100 wineries over 1,000 wines from 15 countries, and is therefore able to offer consumers a comprehensive selection of premium fine wines. These wineries include not only multi-national wine producing groups but also the icon family-owned estates. Our close collaboration with wineries enables us to source wines directly from producers. In this way we can guarantee the authenticity and best quality of the products.',
        to: '/product/acting',
        items: []
    },
    people:{
        desc:'In ASC, we pride ourselves on our highly collaborative, open and friendly work environment. We’re one team, working together to achieve far-reaching and exciting visions and mission.'
    },
    items: [{
        iconfont: 'iconjingyan',
        title: 'EXPERIENCE'
    }, {
        iconfont: 'iconzhenyuan',
        title: 'PROVENANCE'
    }, {
        iconfont: 'iconfuwu',
        title: 'SERVICE'
    }]
}
