import {request} from "../request";

const pre = 'asc/web/'

export default {
    getHomeProduct(inOpts){
        inOpts.cmdDesc = '查看首页产品'
        return request({
            cmd: `${pre}indexByProduct`,
            data: inOpts,
        })
    },
    getHomeEmployee(inOpts){
        inOpts.cmdDesc = '查看首页员工'
        return request({
            cmd: `${pre}indexByEmployee`,
            data: inOpts,
        })
    },
    getHistory(inOpts){
        inOpts.cmdDesc = '发展历程'
        return request({
            cmd: `${pre}history`,
            data: inOpts,
        })
    },
    getSocialOrEmployee(inOpts){
        inOpts.cmdDesc = '企业社会责任/我们的员工'
        return request({
            cmd: `${pre}social`,
            data: inOpts,
        })
    },
    getRecruitment(inOpts){
        inOpts.cmdDesc = '社招/校招'
        return request({
            cmd: `${pre}recruitment`,
            data: inOpts,
        })
    },
    getCity(inOpts){
        inOpts.cmdDesc = '城市'
        return request({
            cmd: `${pre}city`,
            data: inOpts,
        })
    },
    getPosition(inOpts){
        inOpts.cmdDesc = '职位'
        return request({
            cmd: `${pre}recruitment/position`,
            data: inOpts,
        })
    },
    getNews(inOpts){
        inOpts.cmdDesc = '新闻'
        return request({
            cmd: `${pre}news`,
            data: inOpts,
        })
    },
    getNewsDetails(inOpts){
        inOpts.cmdDesc = '新闻详情'
        return request({
            cmd: `${pre}news/details`,
            data: inOpts,
        })
    },
    getEvents(inOpts){
        inOpts.cmdDesc = '活动'
        return request({
            cmd: `${pre}events`,
            data: inOpts,
        })
    },
    getEventsDetails(inOpts){
        inOpts.cmdDesc = '活动详情'
        return request({
            cmd: `${pre}events/details`,
            data: inOpts,
        })
    },
    getExpert(inOpts){
        inOpts.cmdDesc = '专家'
        return request({
            cmd: `${pre}education/expert`,
            data: inOpts,
        })
    },
    getCourse(inOpts){
        inOpts.cmdDesc = '课程'
        return request({
            cmd: `${pre}education/course`,
            data: inOpts,
        })
    },
    getCourseDetails(inOpts){
        inOpts.cmdDesc = '课程详情'
        return request({
            cmd: `${pre}education/course/details`,
            data: inOpts,
        })
    },
    getCountry(inOpts){
        inOpts.cmdDesc = '世界国家'
        return request({
            cmd: `${pre}product/country`,
            data: inOpts,
        })
    },
    getProduct(inOpts){
        inOpts.cmdDesc = '酒庄列表'
        return request({
            cmd: `${pre}product`,
            data: inOpts,
        })
    },
    getProductDetails(inOpts){
        inOpts.cmdDesc = '酒庄详情'
        return request({
            cmd: `${pre}product/details`,
            data: inOpts,
        })
    },
    getOnlineCourse(inOpts){
        inOpts.cmdDesc = '在线课程'
        return request({
            cmd: `${pre}education/onlineCourse`,
            data: inOpts,
        })
    },
}
