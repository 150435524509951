import mock from '../mock'

const files = require.context('./api', false, /\.js$/);
const modules = {};

files.keys().forEach(key => {
    modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
});

const api = {
    ...modules
};

export default process.env.VUE_APP_CURRENTMODE === 'mock' ? mock : api;
