/**
 * Created by Elvira on 2020/5/11.
 */
export default {
    banner:{
        title:'葡萄酒知识传授'
    },
    institution: {
        img: {
            src: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/img_one.jpg'),
            title: '《醉美生活》',
            subTitle: '葡萄酒知识视频'
        },
        title: '中国知名的葡萄酒知识传授机构',
        enTitle: 'LEADING WINE EDUCATION PROVIDER IN CHINA',
        desc: 'ASC精品酒业的葡萄酒讲师团队自21世纪初便致力于向中国消费者推广葡萄酒文化，拥有丰富的' +
        '知识传授经验和杰出的专业水平，至今已为逾2000名中国葡萄酒爱好者及从业人员传授葡萄酒知识。' +
        'ASC精品酒业还以推广葡萄酒文化为己任， 提供内容丰富，模式新颖的知识传授。' +
        'ASC同时也为中国侍酒师的专业培养做出了重要贡献。',
        items: [{numTitle: '第一集', title: '《醉美生活》之《讲师篇》'},
            {numTitle: '第二集', title: '《醉美生活》之《法国篇》'},
            {numTitle: '第三集', title: '《醉美生活》之《澳大利亚与新西兰篇》'},
            {numTitle: '第四集', title: '《醉美生活》之《意大利篇》'},
            {numTitle: '第五集', title: '《醉美生活》之《西班牙篇》'},
            {numTitle: '第六集', title: '《醉美生活》之《北美与南美篇》'},
            {numTitle: '第七集', title: '《醉美生活》之《波尔多篇》'},
            {numTitle: '第八集', title: '《醉美生活》之《勃艮第篇》'},
            {numTitle: '第九集', title: '《醉美生活》之《葡萄牙与西班牙篇》'},
            {numTitle: '第十集', title: '《醉美生活》之《干邑、香槟与波特酒篇》'}]
    },
    team: {
        title: 'ASC葡萄酒专家团队',
        enTitle: 'OUR WINE EDUCATION TEAM',
        typeList: [{id: 1, city: '北京'}, {id: 2, city: '上海'}, {id: 3, city: '广州'}, {id: 5, city: '深圳'}],
        items: [{
            imgUrl: 'services/education/person_one.jpg',
            title: '唐丽燕',
            desc: 'ASC全国培训总监。英国葡萄酒与烈酒教育基金会（WSET）四级Diploma认证及讲师；' +
            '同时担任以下机构的认证讲师：德国葡萄酒协会、澳大利亚葡萄酒学校、法国勃艮第葡萄酒协会(BIVB)、' +
            '法国波尔多葡萄酒协会(CIVB)、西班牙里奥哈协会，新西兰葡萄酒官方课程、巴罗萨葡萄酒官方课程。' +
            '曾在美国担任侍酒师Sommelier一职，常做客多家媒体传播葡萄酒文化。'
        }, {
            imgUrl: 'services/education/person_two.jpg',
            title: '赵娜',
            desc: '英国葡萄酒与烈酒教育基金会（WSET）四级Diploma认证；法国葡萄酒学者最高荣誉；' +
            '葡萄酒生物技术硕士学位；葡萄与葡萄酒工程学士学位；WSET认证讲师及考评官；' +
            '天然软木塞推广大使; 西班牙赫雷斯产区协会雪莉酒高级认证；多次受邀在德国、葡萄牙、东欧、' +
            '韩国等担任国际葡萄酒比赛评委；为多家媒体撰写葡萄酒专栏；主持高端品酒会及晚宴等活动；' +
            '在国内外葡萄酒论坛主讲发言；曾走访国内外数个葡萄酒产区。'
        }, {
            imgUrl: 'services/education/person_three.jpg',
            title: '孔凡保',
            desc: '英国葡萄酒与烈酒教育基金会（WSET）四级（Diploma）认证；波尔多葡萄酒学校（CIVB）' +
            '认证讲师；新西兰葡萄酒官方课程讲师；巴罗萨葡萄酒官方课程讲师；西班牙葡萄酒学院认证讲师；' +
            '葡萄酒俱乐部的创始成员和专业葡萄酒撰稿人。他认为葡萄酒和书籍是生活中不可或缺的动力源泉，' +
            '并总是乐于和身边的朋友们分享葡萄酒的经验和乐趣。'
        }]
    },
    wset: {
        img: {
            src: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/img_two.jpg'),
            title: '2023',
            subTitle: ''
        },
        title: 'ASC WINE PROFESSIONAL PROJECT',
        enTitle: 'ASC WINE PROFESSIONAL PROJECT',
        desc:'',
        // desc: '英国葡萄酒及烈酒教育基金会（WSET）是一所英国的教育慈善机构，它同世界各地的权威教育机构' +
        // '都有着紧密合作，其中一些在葡萄酒酿酒界有着很高的声誉。目前，WEST在全球66个国家开设课程，' +
        // '授课语言已达19种之多。<br>在中国，WSET由WSET认证推荐讲师负责传授。在中国大陆地区的葡萄酒进口商中，' +
        // 'ASC拥有众多的WSET认证推荐讲师。<br>ASC在中国专设的WSET中心同日本，新加坡，韩国，马来西亚的' +
        // '教学分点共同组成WSET在亚洲的基地。2012年，ASC获得英国葡萄酒与烈酒教育基金会授权传授WSET四级' +
        // '（Diploma），成为国内首批获此资质的机构之一。',
        items: [{
            title: 'WSET 1 信息',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET1-CN20.html',
        }, {
            title: 'WSET 2 信息',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET2-CN20.html',
        }, {
            title: 'WSET 3 信息',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET3-CN20.html',
        }, {
            title: 'WSET Diploma 信息',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET Diploma-CN20.html',
        }, {
            title: 'WSET 烈酒1 信息',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET1 Spirits CN20.html',
        }, {
            title: 'WSET 烈酒2 信息',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET2 Spirits CN20.html',
        }]
    },
    course: {
        title: '葡萄酒认证课程',
        enTitle: 'WINE CERTIFICATION COURSE',
        items: [{
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_one.jpg'),
            title: '探索澳大利亚葡萄酒课程',
            desc: '探索澳大利亚葡萄酒课程由澳大利亚葡萄酒管理局研发，为葡萄酒爱好者及从业人员提供知识传授，' +
            '介绍澳大利亚葡萄酒的历史、葡萄品种及产区。<br>课程涵盖22个与澳洲葡萄酒相关的主题，系统地为学员' +
            '讲解气候条件、微气候及产区地理，葡萄酒酿造及产业现状等知识。学生还将品鉴不同产区的代表性酒款，' +
            '并通过热烈的课堂讨论，全方位了解澳大利亚葡萄酒的特性。参与课程获得探索澳大利亚葡萄酒课程的官方' +
            '证书。'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_two.jpg'),
            title: '巴罗萨葡萄酒学院',
            desc: '巴罗萨葡萄与葡萄酒协会是巴罗萨地区种植商及酿造商的官方代表机构，协会代表整个巴罗萨' +
            '产区开发出一套三级认证体系的课程，帮助参与者提高对巴罗萨地区风土和葡萄酒风格的认知。' +
            '<br>以理论学习和酒款品鉴相结合的方式带领学员领略巴罗萨产区的魅力，成功通过考试的学员可获得' +
            '由巴罗萨葡萄与葡萄酒协会颁发的初级结业证书。'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_three.jpg'),
            title: '新西兰葡萄酒',
            desc: '新西兰葡萄酒认证课程由新西兰贸易发展局与新西兰葡萄酒协会联合推出。课程中将品尝到一系列' +
            '新西兰代表性的葡萄酒，参加学习并通过测试的学员可获得由协会颁发的结业证书。<br>在新西兰，' +
            '土壤和气候等自然环境，酒农辛勤耕作和坚守质量承诺的人为动力成就了纯净、热情和多样化的新西兰葡萄酒，' +
            '加入课程，一起踏上新西兰葡萄酒的纯净之旅吧！'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_four.jpg'),
            title: '里奥哈葡萄酒认证课程',
            desc: '里奥哈作为西班牙葡萄酒产区中一颗闪烁的明星，它的酿酒历史可以追溯到3000年前；它是西班牙' +
            '优质原产地认证产区；它的酿酒工艺多元化，传统与现代相融合；它的风土多样，拥有风格强烈的特有葡萄' +
            '品种…<br>里奥哈葡萄酒课程有初级和进阶两个级别，在课上将会了解到里奥哈权威的产区知识与资讯，' +
            '参加学习并成功通过考试的学员将获得里奥哈官方颁发的认证证书。'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_five.jpg'),
            title: '德国葡萄酒认证课程',
            desc: '2017年度，德国葡萄酒协会正式推出German Wine Academy教育体系，欢迎来参加其中的德国' +
            '葡萄酒认证课程（初级）。<br>德国葡萄酒认证课程（初级）内容涵盖德国葡萄酒历史、气候、葡萄品种、' +
            '种植、分级法律及德国酒标等。旨在提高各界葡萄酒消费者对德国葡萄酒的认识，帮助大家了解德国葡萄酒' +
            '的丰富多样性。<br>课程完成后，学员将获得德国葡萄酒协会颁发的官方认证课程证书。'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_six.jpg'),
            title: '阿根廷葡萄酒游学者认证课',
            desc: '阿根廷葡萄酒游学者认证课程由阿根廷葡萄酒协会推出，旨在推广阿根廷葡萄酒丰富多样的' +
            '产区和品种、独一无二的风格魅力以及优异非凡的品质。课程内容采用独一无二的游学形式，' +
            '以Travel, Discover, Fall in Love为线索，既是一堂专业的葡萄酒认证课程又是一次有趣的' +
            '阿根廷葡萄酒时空之旅，让参与者不仅了解了阿根廷葡萄酒，更滋生即刻踏上阿根廷之旅的冲动。'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_seven.jpg'),
            title: 'CIVB',
            desc: '波尔多葡萄酒学校由波尔多葡萄酒行业协会于1990年成立，旨在向全世界各地的葡萄酒爱好者' +
            '及从业人员深入介绍波尔多葡萄酒知识。<br>波尔多葡萄酒学校提供从初级到高级的专业知识，可以按照' +
            '客户的需求而特别定制，内容丰富，介绍包括波尔多葡萄酒品种的鉴定到品鉴的一系列知识。<br>学员' +
            '提供出席证明并通过认证后，可获得由波尔多葡萄酒学校颁布的官方证书。',
            content: ['波尔多葡萄酒历史', '得天独厚的地理环境', '从种植到装瓶',
                '葡萄品种、葡萄酒的酿造及混合、酒标', '品鉴的艺术', '波尔多葡萄酒的六大家族',
                '餐酒搭配']
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_eight.jpg'),
            title: 'BIVB',
            desc: '勃艮第葡萄酒行业协会是1901年依法成立的非盈利组织，旨在确保所有行业成员获得同等权益，' +
            '推动葡萄酒贸易，传承当地的葡萄酒种植技巧与酿造的激情。<br>勃艮第葡萄酒学校将为学员介绍勃艮第' +
            '葡萄酒重要的分级制度和当地风土，以及不同的微气候对葡萄酒的影响。<br>学员通过认证后，将获得' +
            '勃艮第葡萄酒学院颁发的官方证书。',
            content: ['勃艮第葡萄酒历史', '天气，土壤及葡萄种类', '微气候', '分级', '酿酒',
                '品鉴', '勃艮第葡萄酒的餐酒搭配']
        }]
    },
    list: [{
        title: '葡萄酒知识咨询与介绍',
        enTitle: 'CIVB',
        items: [{
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/desc_one.jpg'),
            title: '酒店、餐厅、会所、经销商、零售商及个人',
            desc: '为加强与客户之间的沟通，更好地让客户了解ASC的产品，我们会根据各渠道客户的需求' +
            '为其提供专业的葡萄酒知识咨询，其中包括客户员工的葡萄酒基础知识普及、针对客户酒单所进行' +
            '的产品介绍、传授服务技巧和销售技巧等等。同时，ASC还传授葡萄酒鉴赏知识，主要面向各渠道' +
            '客户和社会各界葡萄酒爱好者，每期都设有不同的主题，在品鉴美酒的同时与大家分享葡萄酒文化。'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/desc_two.jpg'),
            title: '公司或企业团体',
            desc: 'ASC可根据不同需求为客户量身定制精品葡萄酒专业知识介绍或相关主题活动，至今已和' +
            '众多知名企业和团体有过成功合作。合作形式包括开设葡萄酒趣味课堂，为企业的VIP客户或员工' +
            '提供生动的葡萄酒知识讲解，培养大家对葡萄酒的兴趣，传播葡萄酒文化；举办葡萄酒晚宴或品鉴会，' +
            '在轻松的氛围中和大家分享葡萄酒的魅力所在。'
        }]
    }, {
        title: '定期产品知识学习和介绍',
        enTitle: 'CIVB',
        items: [{
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/desc_three.jpg'),
            title: '内部员工',
            desc: '公司定期组织内部员工进行理论知识、酒款品鉴、新品介绍、品牌推介等多方面的学习，' +
            '旨在提高员工的专业知识水平，深入了解公司品牌和产品特色，为客户提供更为专业的推荐和介绍。' +
            '除此之外，与ASC合作的众多酒庄代表也会不定期来访，安排相应的品牌介绍和酒款品尝。'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/desc_four.jpg'),
            title: '商超促销人员',
            desc: '商超促销人员面对的客户大多是终端消费者，对于消费者而言，时常需要一些购买建议' +
            '和专业指导。公司会定期为商超促销人员介绍葡萄酒产品知识，以保证大家能够更有信心地为客户' +
            '提供专业服务。'
        }]
    }],
    onlineCourse: {
        title: '在线葡萄酒培训课程',
        enTitle: 'ONLINE WINE TRAINING COURSE',
        items: []
    }
}
