/**
 * Created by Elvira on 2020/5/8.
 */
export default {
    list: [{
        title: 'OUR MISSION',
        items: ['To enrich consumers’ life by providing the world’s best wines and Suntory Group brands']
    }, {
        title: 'OUR VISION',
        items: ['To be a quality distribution platform which makes our brands shine', 'To be a lighthouse for wine consumers and trade']
    }, {
        title: 'OUR DNA',
        items: ['Sincerity', 'Provenance', 'Team spirit', 'Lifestyle']
    }, {
        title: 'OUR CREDO',
        items: ['We think<br>and move proactively', 'We express<br>value of products', 'We are a responsible<br>corporate citizen']
    }],
    mission: {
        title: '我们的使命',
        enTitle: 'OUR MISSION',
        desc: 'To enrich consumers’ life by providing the world’s best wines and Suntory Group brands' +
        '<br><br>Our Mission tells why ASC exists. It constantly guides and inspires us. Our products –fine wines from around world as well as quality products from Suntory - provide people with enjoyment of fine things of life. We stay focused on the needs of our customers and consumers. It is our desire to promote abundant and ever-fulfilling lives. '
    },
    vision: {
        title: '我们的愿景',
        enTitle: 'OUR VISION',
        desc: 'Our vision demonstrates what ASC aims to achieve as a leading fine wine importer and distributor in China. We are proud of the brands that we represent and we are confident that ASC is the platform where our brands can grow to impart their radiance. As a lighthouse, we don’t wait until deed of greatness we may do or wait to shed our light afar, we want to brighten every corner where our partners and consumers are.',
        items: [{
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/culture/img_two.jpg'),
            desc: '打造高品质经销平台<br>成就璀璨品牌<br>共享辉煌'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/culture/img_three.jpg'),
            desc: '成为葡萄酒消费者<br>与行业的灯塔'
        }]
    },
    dna: {
        title: '我们的DNA',
        enTitle: 'OUR DNA',
        items: [{
            title: '信义',
            desc: '信实、可靠',
            iconfont: 'iconxinyi'
        }, {
            title: '臻源',
            desc: '货真价实、有源可溯',
            iconfont: 'iconzhenyuan'
        }, {
            title: '团队精神',
            desc: '精诚合作',
            iconfont: 'icontuanduijingshen'
        }, {
            title: '生活方式',
            desc: '引领潮流、勇做先锋',
            iconfont: 'iconshenghuofangshi'
        }]
    },
    credo: {
        title: '我们的信条',
        enTitle: 'OUR CREDO',
        items: [{
            title: '主动思考<br>积极行动<br>未雨绸缪',
        }, {
            title: '我们是负责的企业公民<br>（企业社会责任）',
        }, {
            title: '我们是负责的企业公民<br>（企业社会责任）',
        }]
    },
}
