/**
 * Created by Elvira on 2020/5/8.
 */
export default {
    history: {
        title: 'MILESTONES',
    },
    beer: {
        title: 'THE PREMIUM MALT’S',
        details: 'Aiming to Become the “Most Beloved Beer” of Our Customers Through an “Overwhelmingly Delicious Taste Experience”' +
            '<br><br>The Premium Malt’s will continue to take on new challenges and evolve, proposing richness of flavor to customers and working to revitalize the beer market.' +
            '<br><br>Suntory Beer has been engaged in activities based on our belief that foam is what defines beer. We reached this conclusion based on the fact that the carefully selected ingredients, quality brewing methods, and time and effort put into the world’s highest quality products, and the result of focusing on quality at the point of consumption are expressed in the foam. We have been able to expand the picture of The Premium Malt’s deliciousness by drawing attention to and raising awareness of foam with the “Creamy Foam” promotion that we have been running since 2018.' +
            '<br><br>We continued pursuing the true deliciousness of beer in 2019 and worked to change the standard for choosing the most delicious beer to foam.' +
            '<br><br>In 2020, we aim to create “the best version of The Premium Malt’s ever” by evolving both deliciousness and foam quality. We will work to invigorate the market for beer, continuing to evolve and take on new challenges while offering customers an even richer experience.'
    },
    wineries: {
        details1: {
            bannerImg: 'product/wineries/bg_1.jpg',
            logo: 'index/logo_1.jpg',
            title: '露纹酒庄',
            enTitle: 'Leeuwin Estate',
            country: '澳大利亚',
            linkUrl: 'www.leeuwinestate.com.au',
            desc: "It was Napa Valley icon, Robert Mondavi, who first identified the future site of the Leeuwin vineyard in 1972, following an extensive search for the area most suitable to produce the best varietal wines in Australia. With Mondavi providing early mentorship, Denis and Tricia Horgan began the transformation of their cattle farm, establishing Leeuwin Estate in 1973. Leeuwin Estate was established with the ambition of: “Producing wines that rank with the best in the world through the pursuit of Excellence.” A nursery was planted in 1972 and the initial vineyards were planted by hand over a five year period commencing 1973. The winery building, featuring state-of-the-art winemaking facilities, was opened in 1978 and the first commercial vintage was in 1979. With the emphasis on quality rather than quantity, yields at Leeuwin are kept low. The Estate produces only 0.06% of Australia’s wine. Enjoying an isolated and pristine natural environment, the emphasis of Leeuwin’s viticultural practice is sustainable and low interventionist, as the team of highly skilled winemakers produce wines ranking alongside the world's finest.",
            img: 'product/wineries/img_1.jpg',
        },
        details2: {
            bannerImg: 'product/wineries/bg_2.jpg',
            logo: 'index/logo_2.jpg',
            title: '翰斯科酒庄',
            enTitle: 'HENSCHKE',
            country: '澳大利亚',
            linkUrl: 'www.henschke.com.au',
            desc: "The Henschke family has been making wine since Johann Christian Henschke planted a small vineyard by hand on his diverse farming property at Keyneton in 1862. He was one of many Silesians who had fled their homeland in search of religious freedom, and he arrived from Kutschlau in 1841. The wine was initially intended for consumption by family and friends, but with the first commercial release in 1868, the wheels were set in motion for greater things to come. The historic winery and homestead, also built in the 1860s, was constructed from sedimentary stone farmed from the Henschke property, and stands as a testament to their family’s hard work and passion for the land. In more recent times, fourth-generation Cyril Henschke pioneered varietal and single-vineyard wines at a time when blended wines and fortifieds were in vogue. His greatest legacy was the creation of Hill of Grace and Mount Edelstone in the 1950s, Shiraz wines from Eden Valley that have captured the red wine world’s imagination.",
            img: 'product/wineries/img_2.jpg',
        },
        details3: {
            bannerImg: 'product/wineries/bg_3.jpg',
            logo: 'index/logo_3.jpg',
            title: '穆加酒庄',
            enTitle: 'Bodegas Muga',
            country: '西班牙',
            linkUrl: '',
            desc: "Muga Vineyards have a privileged location within the region of Rioja Alta and under the influence of three climates which create an ideal environment for quality grapes to grow in a slow and complex way." +
                "<br><br>For the last 20 years Bodegas Muga has heavily invested in acquiring vineyards in the best locations of the Rioja Alta region. Unique wine making traditional methods: all fermentation and storage in oak, own cooperage facilities with three coopers and a “cubero”, racking by gravity, traditional fininng with egg white." +
                "<br><br>Muga wines have a very distinctive common Soul, all showing the great elegance of the blend of tempranillo with other local grapes but each one with its own well defined personality: from the iconic Prado Enea, the wise and eldest brother, a classic with the unique and delicate style of a top Gran Reserva from Rioja to the power, richness and complexity of Torre Muga and Aro. ",
            img: 'product/wineries/img_3.jpg',
        },
        details4: {
            bannerImg: 'product/wineries/bg_4.jpg',
            logo: 'index/logo_4.jpg',
            title: '三得利登美之丘酒庄',
            enTitle: 'Tomi Ni Oka',
            country: '日本',
            linkUrl: '',
            desc: 'UNTORY TOMI NO OKA WINERY, located in Kofu, Yamanashi Prefecture, overlooks the Kofu Basin with magnificent Mt. Fuji to the south ,and was named for the beauty revealed as one climbs these hills. This idyllic region is blessed with perfect conditions for cultivating grapes, and the winery has been in operation for more than a century since 1909. Year after year, the winery maintains its commitment to quality, heritage and innovation in grape growing and winemaking to craft exceptional wines.',
            img: 'product/wineries/img_4.jpg',
            items: [{
                title: '',
                desc: '',
                img: 'product/wineries/award_4_1.jpg'
            }, {
                title: '',
                desc: '',
                img: 'product/wineries/award_4_2.jpg'
            }, {
                title: '',
                desc: '',
                img: 'product/wineries/award_4_3.jpg'
            }, {
                title: '',
                desc: '',
                img: 'product/wineries/award_4_4.jpg'
            }]
        },
        details5: {
            bannerImg: 'product/wineries/bg_5.jpg',
            logo: 'index/logo_5.jpg',
            title: '玛希庄园 / 瓦尔普利切拉',
            enTitle: 'MASI / VALPOLICELA',
            country: '意大利',
            linkUrl: 'www.masi.it',
            desc: "Founded at the end of the 18th century, the Boscaini family have owned the Masi vineyards for six generations. Modern, pleasant, well-balanced and recognizable wines have earned Masi recognition for having “revolutionized the art of wine -making in the Veneto”. Hugh Johnson has defined these wines as “a touchstone for Veronese oenology”.",
            img: 'product/wineries/img_5.jpg',
        },
        details6: {
            bannerImg: 'product/wineries/bg_6.jpg',
            logo: 'index/logo_6.jpg',
            title: '御兰堡酒庄',
            enTitle: 'Yalumba',
            country: '澳大利亚',
            linkUrl: '',
            desc: 'Yalumba was founded in 1849 by Samuel Smith, British migrant and English brewer. After purchasing a 30-acre parcel of land beyond the southern-eastern boundary of Angaston, Smith and his son began planting the first vines by moonlight. Samuel named his patch "Yalumba" – aboriginal for "all the land around". Five generations and over 160 years later, Yalumba, the very old family owned winery, has grown in size and stature, embodying all that has made the Australian wine success story and the envy of winemakers the world over. Proud of their sustainability, nursery and old vines, Yalumba is also one of the few winemakers in the world with coopers on site.',
            img: 'product/wineries/img_6.jpg',
        },
        details7: {
            bannerImg: 'product/wineries/bg_7.jpg',
            logo: 'index/logo_7.jpg',
            title: '嘉雅 / 托斯卡纳',
            enTitle: 'GAJA / TUSCANY',
            country: '意大利',
            linkUrl: '',
            desc: "With Ca’Marcanda and Pieve Santa Restituta wineries located in Bolgheri and Montalcino (Tuscany), Gaja now offers us an even wider range of highly rated wines, including Brunello di Montalcino Single vineyards and Super Tuscans.<br><br>“No producer anywhere in the last 50 years has made such an impact on the international wine scene!” - Wine Spectator",
            img: 'product/wineries/img_7.jpg',
        },
        details8: {
            bannerImg: 'product/wineries/bg_8.jpg',
            logo: 'index/logo_8.jpg',
            title: '班菲酒厂 / 托斯卡纳',
            enTitle: 'CASTELLO BANFI / TUSCANY',
            country: '意大利',
            linkUrl: 'www.castellobanfi.com/en/home',
            desc: "The Marianis established Castello Banfi in Tuscany in 1978, assembling an estate of 7,100 acres. Banfi is a rarity among Italian wine estates, not just because of its size, its composition of single vineyards, or its state-of-the-art winery, but because it was started from scratch less than 30 years ago." +
                "<br><br>Wine Enthusiast Magazine declared the estate a ‘Winery of the Year’ in 2003 and Wine Spectator have ranked Castello Banfi Brunello di Montalcino and the Riserva Poggio all’ Oro Brunello di Montalcino in their top 10 wines of the year twice in the last three years." +
                "<br><br>“There are wineries, and then, there are SuperWineries: Producers that single-handedly raise the stakes for everyone and become an emblem of a region. Castello Banfi is Montalcino’s SuperWinery”. – Monica Larner, Wine Enthusiast, April ‘2006’",
            img: 'product/wineries/img_8.jpg',
        },
        details9: {
            bannerImg: 'product/wineries/bg_9.jpg',
            logo: 'index/logo_9.jpg',
            title: '布琅兄弟',
            enTitle: 'BROWN BROTHERS',
            country: '澳大利亚',
            linkUrl: '',
            desc: 'Founded in 1889 by John Francis Brown, Brown Brothers is one of Australia’s leading family-owned wine companies, with the family making wine in Victoria for over 130 years. For 130 years the Brown Family’s sense of adventure and innovation has led us to create new wine varieties, push boundaries and develop vineyards in some of the best regions in Australia. We have evolved from a single brand to a family of brands that now includes Brown Brothers, Devil’s Corner, Tamar Ridge, Pirie and Innocent Bystander. From the first vintage in Milawa, in Victoria’s King Valley in 1889, to the multifaceted business we are today, we pride ourselves on our family heritage, our connection to consumers whilst being a valuable partner to our customers. The third and fourth generations of the family currently are custodians of the business. Family keeps the innovation tradition alive with over 60 different wines available for tasting.',
            img: 'product/wineries/img_9.jpg',
        },
        details10: {
            bannerImg: 'product/wineries/bg_10.jpg',
            logo: 'index/logo_10.jpg',
            title: '怡园酒庄',
            enTitle: 'Grace Vineyard',
            country: '中国',
            linkUrl: 'www.grace-vineyard.com',
            desc: 'In August, 1997, Mr. Chun-Keung Chan started Grace Vineyard with the help of Bordeaux oenologist Denis Boubals and French partner Sylvain Janvier. Mr. Chan has in mind of a serene garden – Yi Yuan, the Chinese name of the winery. He hopes the vineyard and its wines remind as his own garden, a place to enjoy great wine	with good company. Under Judy’s leadership, the winery has won international recognition and worldwide acclaim, including from famous wine critics Jancis Robinson and James Halliday, as well as the Wine Spectator, Decanter, and other wine magazines.',
            img: 'product/wineries/img_10.jpg',
        },
        details11: {
            bannerImg: 'product/wineries/bg_11.jpg',
            logo: 'index/logo_11.jpg',
            title: '普西河谷/ 伊甸谷',
            enTitle: 'Pewsey Vale Vineyard/ Eden Valley',
            country: '澳大利亚',
            linkUrl: 'www.pewseyvale.com',
            desc: 'High in the hills surrounding the Barossa Valley lies Pewsey Vale, Eden Valley’s pioneer vineyard. Englishman Joseph Gilbert established the vineyard in 1847, trialling several grape varieties, including Riesling. The site was later purchased by well-known grazier Geoff Angas-Parsons. In 1961 Angas-Parsons called on friend Wyndham Hill-Smith to assist him in developing the historic vineyard site. After researching old records and studying the vineyard’s climate, soil types and suitable clones, the pair believed the site provided the perfect conditions to develop delicate Rieslings in the Germanic style. In the ensuing 12 months, Wyndham established a contoured Riesling vineyard upon the slopes of the estate. Today Pewsey Vale Vineyard is dedicated to a single variety – Riesling.',
            img: 'product/wineries/img_11.jpg',
        },
    },
    water: {
        // desc1: 'The Ferrarelle sparkling natural mineral water springs from an ancient unique volcanic source located in the territory of Riardo in the South of Italy, about 60km of Naples.<br>The spring water is enriched with several minerals emerging with natural fine bubbles that is then mixed with additional natural carbon dioxide from the source to give Ferrarelle water its unique taste and create its incomparable sparkle.<br>Now, Ferrarelle is the favorite brand of the Italian families and the brand with the highest top of mind in Italy'
        items: [{
            logoName: '', /*NATURAL MINERAL WATER*/
            desc: 'evian needs no introduction, as it is one of the most recognised water brands in China and over the world. evian® Natural Mineral Water, from French Alps, after many years natural filter and mineralization of rocks and sands, is pure and natural, away from pollution.  evian came to China market since 1986, focused on premium target audience in key cities and delivered natural, healthy, young and fashion lifestyle with the brand equity of “live young”.',
        }, {
            logoName: '', /*SPARKLING NATURAL MINERAL WATER*/
            desc: 'Badoit is a major sparkling water brand much-loved by the French people. Badoit brings over two centuries of pleasure and sophistication to every meal. Choosing to enjoy Badoit fine bubbles means making each meal a special occasion. A long-standing advocate of BADOIT, Robert Parker Jr has long championed the sparkling natural mineral water. Speaking about the partnership Robert Parker Jr said, “I have been drinking Badoit with my wine for almost 10 years now. It is the perfect accompaniment to the fine wines I drink as the bubbles naturally highlight the unique flavours in wine”. It is rapidly becoming the brightest sparkling star in the finest dining establishments in China.'
        }, {
            logoName: '', /*SPARKLING NATURAL MINERAL WATER*/
            desc: 'The VOSS source sits in Iveland, a sparsely populated area on the southern tip of Norway. The exceptional purity of VOSS, and its uniquely fresh, clean taste, are due in part to the unusually low levels of Total Dissolved Solids (TDS) naturally occurring in the VOSS source which has 44mg/I only.<br><br>VOSS is served on the tables of the most discerning consumers, in the rooms of the top hotels, and at the finest restaurants. Total dissolved solids (TDS) is a measure of the combined total of organic and inorganic substances contained in a liquid.'
        }, {
            logoName: '',
            desc: 'Ferrarelle natural mineral water with natural gas originates from the Ricardo region in southern Italy, about 60 kilometers away from Naples. The source of water is located in a volcano in Italy, with the characteristics of vitality and passion. The rainwater on the volcano slowly flows through the underground rock layers for more than 30 years. During this process, the rainwater is filtered layer by layer, penetrates deep into the ground, and collects various minerals and natural carbon dioxide. Therefore, Ferrarelle has natural ingredients and delicate bubbles.'
        }]
    },
    news: {
        title: 'NEWS CENTER',
        list: [{
            img: 'company/news/img_one.jpg',
            title: 'Confronting Covid-19 – Suntory and ASC Take CSR Action',
            subTitle: 'Mr. Takeshi Niinami, CEO of Suntory and Mr. Kong Xuanyou, Chinese ambassador to Japan held a meeting on 25 February, in which Mr. Niinami donated JPY50million on behalf of Suntory to the Chinese government to support its relief work in response to the novel coronavirus outbreak in China.',
            pubTime: '2020-02-28'
        }, {
            img: 'company/news/img_two.jpg',
            title: 'ASC Launches Henschke in Mainland China',
            subTitle: '(24 October 2017, Shanghai, China) ASC Fine Wines, the leader in the distribution of fine wines in China is very pleased to announce that it has become the exclusive importer and distributor of Henschke, a constantly award-winning wine producer from Barossa, Australia.',
            pubTime: '2020-02-28'
        }, {
            img: 'company/news/img_three.jpg',
            title: 'ASC Fine Wines and Domaines Barons de Rothschild (Lafite) Partnership Opens a New Chapter',
            subTitle: 'ASC Fine Wines, China’s leading fine wine importer and distributor announced today a new deal with DBR (Lafite). To support sustainable development in the ever changing market in China, agreed by both parties, ASC will retain the exclusive distribution of DBR (Lafite)’s Vina Los Vascos from Chile and Domaine d’Aussieres from France in Mainland China, Hong Kong and Macau. This opens a new chapter in the history of ASC and DBR (Lafite) partnership.',
            pubTime: '2020-02-28'
        }, {
            img: 'company/news/img_four.jpg',
            title: 'ASC Fine Wines announces exclusive distribution partnership with SUNTORY TOMI NO OKA WINERY in the Chinese mainland',
            subTitle: '(26 September 2018 – Shanghai, China)   ASC Fine Wines, China’s leading wine importer, is very pleased to announce that it has been appointed as the exclusive importer and distributor of Japanese wines from SUNTORY TOMI NO OKA winery, a constantly award-winning Japanese wine producer owned by ASC’s parent company, Suntory Wine International Limited, in the Chinese mainland effective from 29 October, 2018.',
            pubTime: '2020-02-28'
        }]
    },
    events: {
        typeList: [{id: 1, title: 'All', type: 'all'}, {id: 2, title: 'PARTY', type: 'party'}, {
            id: 3,
            title: 'WINE tasting',
            type: 'wineTasting'
        }, {
            id: 4,
            title: 'Wine dinner',
            type: 'wineDinner'
        }, {id: 5, title: 'Shop activity', type: 'shopActivity'}, {id: 6, title: 'ICON EVENTS', type: 'iconEvents'}],
    },
    employee: {
        banner: {
            title: 'OUR PEOPLE'
        },
        desc: 'In ASC, we pride ourselves on our highly collaborative, open and friendly working environment.<br>We’re one team, working together to achieve far-reaching and exciting visions and mission.',
        title: 'Hear why some of our employees<br>love working at ASC:',
        join: {
            title: 'JOIN US',
            schoolTitle: 'Campus',
            socialTitle: 'Social',
            otherBanner: {
                title: 'Join ASC, in your work,',
                enTitle: 'every day, every place celebrates life.'
            }
        }
    },
    product: {
        banner: {
            title: 'Wines'
        },
    }
}
