/**
 * Created by Elvira on 2020/4/27.
 */
export default {
    about: {
        banner:{
            title: 'ABOUT ASC',
        },
        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/about/img_one.jpg'),
        title: 'OUR BEGINNING',
        enTitle: 'OUR BEGINNING',
        desc: 'In the mid-1990s, Don St. Pierre Sr. and his son believed China’s wine importing industry had the potential to flourish. The opening of China’s economy and the rapid expansion in consumer income along with the limited production of wines in China, have all combined to prove ASC’s expectations. Since 1996 when ASC was first established, the company has developed beyond recognition from those early days.',
    },
    development: {
        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/about/bg_two.jpg'),
        title: 'ASC DEVELOPMENT',
        enTitle: 'ASC DEVELOPMENT',
        desc: 'In 2010, ASC Fine Wines became a member company of The Suntory Group. Suntory has a “Yatte Minahare – Go for it!” spirit since its founding over 100 years ago that encourages its team to pursue challenges that others will not and contribute to the society by creating new values. These same entrepreneurial values and innovative spirit sustained ASC for over two decades and it will continue to guide ASC as the leader of China’s wine import industry and as your trustworthy fine wine provider.',
        items: [{
            title: 'Experience',
            desc: 'ASC has been fortunate to be able to select the finest wines from the best-known producers in the world. To date, ASC Fine Wines exclusively represents over 1,000 different wines from 15 countries, offering consumers in China a comprehensive selection, exceptional service and options for fine wines at all price points.'
        }, {
            title: 'Customers',
            desc: 'ASC is grateful for the support of the many customers that it works with. Its customers come from a diverse range of channels and cities across the country – hotels and restaurants, retail shops, supermarkets, wholesalers and individual consumers who are wine lovers.'
        }]
    },
    service: {
        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/about/img_two.jpg'),
        title: 'OUR EXPERTISE',
        enTitle: 'OUR EXPERTISE',
        desc: 'The ASC team brings great passion and knowledge of wine to work each day. Its relentless focus on customer service is one of the main reasons behind its success. In fact, ASC is more than a wine importer and distributor. Its professional team is able to provide customers with total solutions and bespoke service to meet their diversified needs for fine wines.' +
        '<br><br>ASC is focused more than sales. It is committed to building the market by providing wine knowledge and education to both the trade and wine consumers in China. ASC has dedicated wine education professionals who help train all ASC staff so that its customer-facing team understand the products and customers’ business needs. As a part of the service, ASC’s wine education team also provides tailor-made training programmes to customers’ staff so that they will be equally equipped with professional wine knowledge to provide remarkable consumer experience to their customers. Exceeding customer’s expectation is always ASC’s key to success.' +
        '<br><br>Professional logistics is a pivotal factor to safe guard the quality of wines in the process of transportation. Orders are delivered promptly from one of ASC’s 5 temperature-controlled warehouses in Beijing, Shanghai, Guangzhou, Chengdu and Hong Kong. This ensures that ASC can deliver customer orders on time, in full, and in uncompromised condition.',

    },
    product: {
        title: 'COMPANY PRODUCTS',
        enTitle: 'COMPANY PRODUCTS',
        desc: 'ASC is focused more than sales. It is committed to building the market by providing wine knowledge and education to both the trade and wine consumers in China. ASC has dedicated wine education professionals who help train all ASC staff so that its customer-facing team understand the products and customers’ business needs. As a part of the service, ASC’s wine education team also provides tailor-made training programmes to customers’ staff so that they will be equally equipped with professional wine knowledge to provide remarkable consumer experience to their customers. Exceeding customer’s expectation is always ASC’s key to success.' +
        '<br><br>Professional logistics is a pivotal factor to safe guard the quality of wines in the process of transportation. Orders are delivered promptly from one of ASC’s 5 temperature-controlled warehouses in Beijing, Shanghai, Guangzhou, Chengdu and Hong Kong. This ensures that ASC can deliver customer orders on time, in full, and in uncompromised condition.',
    },
    team: {
        img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/file/6931712730861_.pic.jpg',
        title: 'EXECUTIVE TEAM',
        enTitle: 'EXECUTIVE TEAM',
        desc: '',
        items: [
            {
            imgUrl: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/company/about/teamnew-1.jpg',
            name: 'Makoto Nagae',
            position: 'Chief Executive Officer',
            desc: 'Mr. Makoto Nagae (Makoto) has been appointed as the Chief Executive Officer of ASC Fine Wines effective from 15 June 2021.<br><br>After his graduation from Kobe City University of Foreign Studies in 1990, with a Bachelor degree of Chinese language major, Makoto joined Suntory Group in Japan. Since 2003 Makoto has been assigned to work in the Greater China across various regions and business units of Suntory Group. Prior to his appointment in ASC, Makoto was the Managing Director of Beam Suntory Taiwan.<br><br>With his experience, talent and knowledge in understanding the Greater China market plus his outstanding leadership, he will makes important contribution to define and carry out the long term strategy for ASC as well as to drive sustainable long term value for the company.'
        },{
            imgUrl: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/file/6941712730866_.pic.jpg',
            name: 'Alex Iida',
            position: 'Chief Financial Officer',
            desc: 'Alex took his office at ASC Fine Wines on April 1, 2024. He oversees the finance, business planning and analysis, supply chain management, IT, and legal departments. As an integral part of ASC’s EXCOM, Alex drives cross-functional collaboration, spearheading efforts to spot opportunities, tackle obstacles, and optimise resource utilisation. His efforts are instrumental in steering ASC towards its business objectives, enhancing profitability, and fostering the sustainable growth of the company.'
        }, {
            imgUrl: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/company/about/teamnew-4.jpg',
            name: 'Eileen Pan',
            position: 'Senior Human Resources Director',
            desc: 'Eileen joined ASC Fine Wines as Senior Human Resources Director in July, 2017，fully responsible for ASC’s Human Resources Division.<br><br>Eileen has come from a solid HR background with sound operation experience in organization design, C&B, staffing & retention, people development and strategy planning to support business growth. Prior to joining ASC, Eileen was HR Director with numerous top FMCG Companies and Luxury brands, Estée Lauder China, L’Oréal China, Dazzle Fashion and Swatch Group China.'
        }]
    }
}
