/**
 * Created by Elvira on 2020/5/8.
 */
export default {
    tel:'Tel',
    fax:'Fax',
    list: [{
        name: 'ASC Fine Wines – Headquarters',
        address: 'Central Park Jing’an, 23F, No. 329, Hengfeng Road, Shanghai 200070 China',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    }, {
        name: 'ASC Fine Wines – Shanghai',
        address: 'Central Park Jing’an, 23F, No. 329, Hengfeng Road, Shanghai 200070 China',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    }, {
        name: 'ASC Fine Wines – Beijing',
        address: 'Office 01-101，02-211，02-215 ，Beijing Fun, WEWORK,13， No.15 ,Langfang Toutiao, BLDG E16, Xicheng, Beijing',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    }, {
        name: 'ASC Fine Wines – Guangzhou',
        address: '5804A, No. 68, Zhujiang Xinhuacheng Avenue, Tianhe District, Guangzhou',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    }, {
        name: 'ASC Fine Wines – Shenzhen',
        address: 'Level 4, Loft B, The Town, UpperHills, 5001 Huanggang Road, Futian District, Shenzhen, China',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    },
    //     {
    //     name: 'ASC Fine Wines – Xiamen',
    //     address: 'The Bank Center, 27/F, Unit 2703A, No.189 Xiahe Road, Xiamen',
    //     phone: '+86 592 2999688',
    //     fax: '+86 592 2999681'
    // },
    //     {
    //     name: 'ASC Fine Wines – Wuhan',
    //     address: 'Room 07-130, 7/F, WeWork Oceanwide Fortune Center, 189 Yun Xia Lu, Jianghan, Wuhan',
    //     phone: '027-88852952, 82895508'
    // },
        {
        name: 'ASC Fine Wines – Chengdu',
        address: 'Room1003-1004,10th floor, Shangri-la Office Building, NO.6, Binjiang Road, Chengdu, Sichuan Province, China',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    }, {
        name: 'ASC Fine Wines – Hong Kong',
        address: 'Units 1101-02 & 1112-13, 11/F, Two Harbourfront, 22 Tak Fung Street, Hunghom, Kowloon, Hong Kong',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    },
    //     {
    //     name: 'ASC Fine Wines – Xi’an',
    //     address: 'Unit2109, Building B, Wanxianghui, Gaoxinliu Road, Xi’an'
    // }
    ]
}
