/**
 * Created by Elvira on 2020/5/8.
 */
export default {
    tel:'电话',
    fax:'传真',
    list: [{
        name: 'ASC 精品酒业-总部',
        address: '中国上海市恒丰路329号中港汇静安23楼',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    }, {
        name: 'ASC 精品酒业-上海',
        address: '中国上海市恒丰路329号中港汇静安23楼',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    }, {
        name: 'ASC 精品酒业-北京',
        address: '北京市西城区廊房头条15号1层01号、2层11号、15号',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    }, {
        name: 'ASC 精品酒业-广州',
        address: '广州市天河区珠江新城花城大道68号环球都会广场5804A',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    }, {
        name: 'ASC 精品酒业-深圳',
        address: '广东省深圳市福田区皇岗路5001号深业上城小镇南区LOFTB4层',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    },
    //     {
    //     name: 'ASC 精品酒业-厦门',
    //     address: '厦门市厦禾路189号银行中心大厦2703A单元',
    //     phone: '+86 592 2999688',
    //     fax: '+86 592 2999681'
    // },
    //     {
    //     name: 'ASC 精品酒业-武汉',
    //     address: '武汉市江汉区云霞路189号WeWork泛海创业中心7楼07-130室',
    //     phone: '027-88852952, 82895508'
    // },
        {
        name: 'ASC 精品酒业-成都',
        address: '四川省成都市锦江区滨江路9号香格里拉写字楼1003-1004',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    }, {
        name: 'ASC 精品酒业-香港',
        address: '香港九龍紅磡德豐街22號海濱廣場二座11樓1101-02 & 1112-13室',
        phone: '+86 21 22508699',
        fax: '+86 21 22508600'
    },
    //     {
    //     name: 'ASC 精品酒业-西安',
    //     address: '西安市高新区高新6路万象汇B座2109'
    // }
    ]
}
