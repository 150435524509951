import {i18n} from '@/plugins/vuetify'

export default [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/index/index.vue'),
        meta: {
            title: i18n.t('common.Home')
        }
    },
    {
        path: '/product',
        name: 'productWines',
        component: () => import('@/pages/product/wines.vue'),
        meta: {
            title: i18n.t('common.Home')
        }
    },
    {
        path: '/company/about',
        name: 'about',
        component: () => import('@/pages/company/about.vue'),
        meta: {
            title: i18n.t('common.company.About Us')
        }
    },
    {
        path: '/company/news',
        name: 'news',
        component: () => import('@/pages/company/news.vue'),
        meta: {
            title: i18n.t('common.company.Press')
        }
    },
    {
        path: '/company/newsDetails',
        name: 'newsDetails',
        component: () => import('@/pages/company/newsDetails.vue'),
        meta: {
            title: i18n.t('common.details')
        }
    },
    /*{
        path: '/company/philosophy',
        name: 'philosophy',
        component: () => import('@/pages/company/culture.vue'),
        meta: {
            title: i18n.t('common.company.Enterprise')
        }
    },*/
    {
        path: '/company/social',
        name: 'social',
        component: () => import('@/pages/company/social.vue'),
        meta: {
            title: i18n.t('common.company.Social Responsibility')
        }
    },
    {
        path: '/company/history',
        name: 'history',
        component: () => import('@/pages/company/history.vue'),
        meta: {
            title: i18n.t('common.company.History')
        }
    },
    {
        path: '/company/contact',
        name: 'contact',
        component: () => import('@/pages/company/contact.vue'),
        meta: {
            title: i18n.t('common.company.Contact Us')
        }
    },
    /*{
        path: '/company/asctaxid',
        name: 'asctaxid',
        component: () => import('@/pages/company/asctaxid.vue'),
        meta: {
            title: i18n.t('common.company.ASC Tax ID')
        }
    },
    {
        path: '/services/events',
        name: 'events',
        component: () => import('@/pages/services/events.vue'),
        meta: {
            title: i18n.t('common.services.Events')
        }
    },
    {
        path: '/services/eventsDetails',
        name: 'eventsDetails',
        component: () => import('@/pages/services/eventsDetails.vue'),
        meta: {
            title: i18n.t('common.services.Events')
        }
    },*/
    {
        path: '/product/acting',
        name: 'acting',
        component: () => import('@/pages/product/acting.vue'),
        meta: {
            title: i18n.t('common.products.Acting Red Wine')
        }
    },
    {
        path: '/product/wineries',
        name: 'wineries',
        component: () => import('@/pages/product/wineries.vue'),
        meta: {
            title: i18n.t('common.wineries')
        }
    },
    {
        path: '/product/beer',
        name: 'beer',
        component: () => import('@/pages/product/beer.vue'),
        meta: {
            title: i18n.t('common.products.Beer')
        }
    },
    {
        path: '/product/gcc',
        name: 'gcc',
        component: () => import('@/pages/product/gcc.vue'),
        meta: {
            title: i18n.t('common.products.GCC')
        }
    },
    {
        path: '/services/finewines',
        name: 'finewines',
        component: () => import('@/pages/services/finewine.vue'),
        meta: {
            title: i18n.t('common.products.GCC')
        }
    },
    {
        path: '/product/water',
        name: 'water',
        component: () => import('@/pages/product/water.vue'),
        meta: {
            title: i18n.t('common.products.Water')
        }
    },
{
    path: '/services/education',
        name: 'education',
    component: () => import('@/pages/services/education.vue'),
    meta: {
    title: i18n.t('common.services.Education')
}
},
{
    path: '/services/preview',
        name: 'preview',
    component: () => import('@/pages/services/preview.vue'),
    meta: {
    title: i18n.t('common.app_title')
}
},
    /*{
        path: '/services/primeur',
        name: 'primeur',
        component: () => import('@/pages/services/primeur.vue'),
        meta: {
            title: i18n.t('common.services.En Primeur')
        }
    },
    {
        path: '/services/education',
        name: 'education',
        component: () => import('@/pages/services/education.vue'),
        meta: {
            title: i18n.t('common.services.Education')
        }
    },
    {
        path: '/services/preview',
        name: 'preview',
        component: () => import('@/pages/services/preview.vue'),
        meta: {
            title: i18n.t('common.app_title')
        }
    },
    {
        path: '/services/auction',
        name: 'auction',
        component: () => import('@/pages/services/auction.vue'),
        meta: {
            title: i18n.t('common.services.Auction')
        }
    },*/
    {
        path: '/employee/index',
        name: 'employee',
        component: () => import('@/pages/employee/index.vue'),
        meta: {
            title: i18n.t('common.staff.Employees')
        }
    },
    {
        path: '/employee/team',
        name: 'team',
        component: () => import('@/pages/employee/team.vue'),
        meta: {
            title: i18n.t('common.staff.Team')
        }
    },
    {
        path: '/employee/join',
        name: 'join',
        component: () => import('@/pages/employee/join.vue'),
        meta: {
            title: i18n.t('common.staff.Join Us')
        }
    },
    {
        path: '/employee/join/school',
        name: 'schoolJoin',
        component: () => import('@/pages/employee/join/school.vue'),
        meta: {
            title: i18n.t('common.staff.Join Us')
        }
    },
    {
        path: '/employee/join/social',
        name: 'socialJoin',
        component: () => import('@/pages/employee/join/social.vue'),
        meta: {
            title: i18n.t('common.staff.Join Us')
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/pages/profile/index.vue'),
        meta: {
            title: "ASC精品酒业"
        }
    }
];
