<template>
    <app-layout></app-layout>
</template>

<script>


  import AppLayout from "@/layouts/default";

  export default {
    name: 'App',

    components: {AppLayout},

    data: () => ({
      //
    }),
  };
</script>

<style lang="scss">

</style>
