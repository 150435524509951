/**
 * Created by Elvira on 2020/3/30.
 */
export default{
    languageType: '中',
    navList: [{
        key: 'common.Home',
        to: '/',
        name: '首页'
    }, {
        key: 'common.Company',
        to: '',
        name: '公司',
        items: [{
            key: 'common.company.About Us',
            to: '/company/about',
            name: '关于我们'
        }, {
            key: 'common.company.History',
            to: '/company/history',
            name: '发展历程'
        }/*, {
            key: 'common.company.Enterprise',
            to: '/company/philosophy',
            name: '企业文化'
        }*/, {
            key: 'common.company.Social Responsibility',
            to: '/company/social',
            name: '企业社会责任'
        }, {
            key: 'common.company.Press',
            to: '/company/news',
            name: '新闻资讯'
        }, {
            key: 'common.company.Contact Us',
            to: '/company/contact',
            name: '联系我们'
        }/*, {
            key: 'common.company.ASC Tax ID',
            to: '/company/asctaxid',
            name: 'ASC纳税人识别号'
        }*/]
    }, {
        key: 'common.Products',
        to: '',
        name: '我们的产品',
        items: [{
            key: 'common.products.Acting Red Wine',
            to: '/product/acting',
            name: '葡萄酒'
        }, {
            key: 'common.products.Water',
            to: '/product/water',
            name: '水'
        }, /*{
            key: 'common.products.Beer',
            to: '/product/beer',
            name: '啤酒'
        }, {
            key: 'common.products.Liquor',
            to: '',
            name: '烈酒'
        }, {
            key: 'common.products.Group Products',
            to: '',
            name: '集团产品'
        }*/]
    }, {
        key: 'common.Staff',
        to: '',
        name: '员工',
        items: [/*{
            key: 'common.staff.Responsibilities',
            to: '',
            name: '我们的职责'
        },*/ {
            key: 'common.staff.Employees',
            to: '/employee/index',
            name: '我们的员工'
        },{
            key: 'common.staff.Team',
            to: '/employee/team',
            name: '我们的员工'
        }, {
            key: 'common.staff.Join Us',
            to: '/employee/join',
            name: '加入我们'
        }]
    }, {
        key: 'common.Services',
        to: '',
        name: '我们的服务',
        items: [/*{
            key: 'common.services.Events',
            to: '/services/events',
            name: '葡萄酒活动'
        },*/ {
            key: 'common.services.Education',
            to: '/services/education',
            name: '葡萄酒知识传授'
        }, {
            key: 'common.products.GCC',
            to: '/services/finewines',
            name: 'Fine wine service'
        }/*, {
            key: 'common.services.En Primeur',
            to: '/services/primeur',
            name: '期酒'
        }, {
            key: 'common.services.Auction',
            to: '/services/auction',
            name: '精品葡萄酒拍卖'
        }*/]
    }, {
        key: 'common.On Line',
        to: '',
        name: '线上购买',
        items: [{
            key: 'common.onLine.Jingdong',
            to: '',
            href:'https://mall.jd.com/index-35946.html',
            name: 'ASC京东旗舰店'
        },{
            key: 'common.onLine.Tianmao',
            to: '',
            href:'https://asc.tmall.com/',
            name: 'ASC天猫旗舰店'
        }/*, {
            key: 'common.onLine.Wechat',
            to: '',
            name: '微信小程序',
            qrcode: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/wechat-code.png'
        }*/]
    }]
}
