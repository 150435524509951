/**
 * Created by Elvira on 2020/5/11.
 */
export default {
    banner:{
        title:'WINE EDUCATION'
    },
    institution: {
        img: {
            src: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/img_one.jpg'),
            title: '《醉美生活》',
            subTitle: '葡萄酒知识视频'
        },
        title: 'Leading Wine Education Provider in China',
        enTitle: 'Leading Wine Education Provider in China',
        desc: 'ASC’s wine education team has expertise and experiences in training over 2,000 wine professionals and enthusiasts in China since 1996. Our extensive portfolio of professional wine courses include many prestigious wine education programs in the world. We are also a key supporter of sommelier education in China.',
        items: [{numTitle: '第一集', title: '《醉美生活》之《讲师篇》'},
            {numTitle: '第二集', title: '《醉美生活》之《法国篇》'},
            {numTitle: '第三集', title: '《醉美生活》之《澳大利亚与新西兰篇》'},
            {numTitle: '第四集', title: '《醉美生活》之《意大利篇》'},
            {numTitle: '第五集', title: '《醉美生活》之《西班牙篇》'},
            {numTitle: '第六集', title: '《醉美生活》之《北美与南美篇》'},
            {numTitle: '第七集', title: '《醉美生活》之《波尔多篇》'},
            {numTitle: '第八集', title: '《醉美生活》之《勃艮第篇》'},
            {numTitle: '第九集', title: '《醉美生活》之《葡萄牙与西班牙篇》'},
            {numTitle: '第十集', title: '《醉美生活》之《干邑、香槟与波特酒篇》'}]
    },
    team: {
        title: 'OUR WINE EDUCATION TEAM',
        enTitle: 'OUR WINE EDUCATION TEAM',
        typeList: [{id: 1, city: 'Beijing'}, {id: 2, city: 'Shanghai'}, {id: 3, city: 'Guangzhou'}, /*{
            id: 4,
            city: 'Hongkong'
        },*/ {id: 5, city: 'Shenzhen'}],
        items: [{
            imgUrl: 'services/education/person_one.jpg',
            title: 'Dorian Tang',
            desc: 'National Education Director of ASC. WSET Diploma Certificate holder. Certified tutor of the following institutes and courses: WSET, Wine Australian, Burgundy Wine School (BIVB), Bordeaux Wine School (CIVB), New Zealand wine association, Wines of Germany, Rioja wines and Barossa Wine School. She has gained wide experience of wines when working as a Sommelier in the USA for over five years.'
        }, {
            imgUrl: 'services/education/person_two.jpg',
            title: 'Constance Zhao',
            desc: 'WSET Diploma Certificate holder; French Wine Scholar Highest Honor; Master’s Degree of Wine Biochemistry; Bachelor’s Degree of Viticulture and Enology; WSET Certified Educator & Assessor. Natural Cork Ambassador; Advanced certification in Sherry of Jerez Association; Jury member of International Wine Competitions, such as Berlin Wein Trophy, Portugal Wine Trophy, Asia Wine Trophy, Eurasia Wine Competition, etc.; Wine Columnist; She hosted numerous professional tastings and dinners; She was keynote speaker in wine forums of both home and abroad; She visited multiple wine regions around the world.'
        }, {
            imgUrl: 'services/education/person_three.jpg',
            title: 'Chris Kong',
            desc: 'WSET Diploma Certificate holder; Bordeaux Wine School (CIVB) Certified Tutor; New Zealand Wine School Tutor; Barossa Wine School Tutor; Spanish Wine Educator; One of the founding members of wine club & Wine Writer. Wines and books are his life-long companions and he is always eager to share his wine experience with others.'
        }]
    },
    wset: {
        img: {
            src: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/img_two.jpg'),
            title: '2023',
            subTitle: ''
        },
        title: 'ASC WINE PROFESSIONAL PROJECT',
        enTitle: 'ASC WINE PROFESSIONAL PROJECT',
        desc: '',
        // desc: 'Wine & Spirits Education Trust (or WSET) is a UK based educational charity that works closely with educational partners and regulatory authorities around the world to provide educational courses, some of which lead to professional qualifications widely recognized by the wine industry. WSET is now represented in over 66 countries worldwide, and the qualifications are currently available in 19 different languages.' +
        // '<br>The WSET programs administered in China are delivered by WSET Approved Tutors. In Mainland China, ASC has the largest number of WSET Approved Tutors among all wine importers.' +
        // '<br>ASC’s network of WSET centers in China joins others in WSET’s Asian network, such as Japan, Singapore, Korea and Malaysia. In 2012, ASC became the first WSET Diploma Course Provider in Mainland China, authorized by WSET headquarters in the UK.',
        items: [{
            title: 'WSET 1 Class Information',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET1-CN20.html',
        }, {
            title: 'WSET 2 Class Information',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET2-CN20.html',
        }, {
            title: 'WSET 3 Class Information',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET3-CN20.html',
        }, {
            title: 'WSET Diploma Class Information',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET Diploma-CN20.html',
        }, {
            title: 'WSET L1 Spirits Class Information',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET1 Spirits CN20.html',
        }, {
            title: 'WSET L2 Spirits Class Information',
            linkUrl: 'http://shopping.asc-wines.com:8523/WSET2 Spirits CN20.html',
        }]
    },
    course: {
        title: 'WINE CERTIFICATION COURSE',
        enTitle: 'WINE CERTIFICATION COURSE',
        items: [{
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_one.jpg'),
            title: 'Australia Wine Discovered',
            desc: 'Wine Australia Foundation Education Program is developed by Wine Australia. provides professionals and enthusiasts with an increased understanding of Australian wines, focusing on the major varieties and regions.' +
            '<br>The program features 22 education modules, and includes a structured insight into the climate, micro-climate and geology of the regions, wine production and the current state of the industry. There will be a themed tasting of benchmark wines and lively discussions throughout each module. Upon completion of the course, the participants will receive an official certificate issued by Wine Australia.'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_two.jpg'),
            title: 'Barossa Wine Course',
            desc: 'Barossa Grape & Wine Association (BGWA) is an official representative organisation of wine growers and wine makers in Barossa region, who set up a 3-level certification wine course, aiming to help participants to know more about the terroir and wine styles in Barossa.' +
            '<br>During the class, students will learn to appreciate the charm of Barossa wine region by means of theory study and wine tasting. The participants that pass the examination will be rewarded a graduation certificate issued by BGWA.'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_three.jpg'),
            title: 'New Zealand Wine Course',
            desc: 'New Zealand Wine Course is presented by the New Zealand Winegrowers and New Zealand Trade and Enterprise. In the course, a series of typical New Zealand wines will be tasted. Upon completion of the course, the participants will receive an official certificate. In New Zealand, special combination of soil and climate, the winemakers’ innovative spirit and commitment to quality all come together to create the pure, passionate and diverse wines of New Zealand.  Welcome to the New Zealand pure discovery!'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_four.jpg'),
            title: 'Rioja Wine Course',
            desc: 'Rioja is a shining star among the Spain wine regions, with a rich wine history dating back to over 3000 years ago; Rioja is the first DOCa region in Spain; it has diversified winemaking and integrates tradition with modernism; it has various terroir and many local characteristic varieties…' +
            '<br>The Rioja Wine education program includes a Foundation Level and Advanced Level course, and introduces authoritative and up-to-date knowledge of the wine region. The participants that pass the examination will be rewarded a graduation certificate issued by the Rioja Consejo Regulador.'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_five.jpg'),
            title: 'German Wine Academy Level 1 course',
            desc: 'In 2017, the German Wine Academy education program was released by the Wines of Germany, and you are welcomed to join the German Wine Academy Level 1 course.' +
            '<br>This course will introduce the history, climate, varieties, grape growing, wine law and German wine labeling, etc. It is aimed at improving the participants’ understanding on German wines and the diversity.' +
            '<br>Upon completion of the course, the participants will receive an official certificate issued by Wines of Germany.'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_six.jpg'),
            title: 'Argentina Wine Traveller Certificate Programme',
            desc: 'Argentina Wine Traveller Certificate Programme is subordinate to Wine of Argentina, which aims at promoting the abundant of Argentine wine regions and varieties, unique style charm and outstanding quality. The content of the course is a unique form of study tour with Travel, Discover, Fall in Love as clues. It is not only a professional wine certification course, but also an interesting space tour of Argentine wine, so that participants can not only learn about Argentine wine, but also urge to embark on a trip to Argentina immediately.'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_seven.jpg'),
            title: 'CIVB',
            desc: 'L’Ecole du Vin de Bordeaux (The Bordeaux Wine School) was created by the ConseilInter professionnel du Vin de Bordeaux (CIVB) in 1990 with the aim of bringing a better understanding of Bordeaux wines to professionals and enthusiasts.' +
            '<br>The course allows participants to find their own way around the vast range of Bordeaux wines and to identify and communicate the tastes and sensations they experience.' +
            '<br>Participants who pass the exam receive an official certificate issued jointly by L’Ecole du Vin de Bordeaux and ASC upon confirmation of attendance.',
            content: ['Bordeaux wine history', 'An outstanding geographical location', 'From vine to bottle',
                'Grape varieties, blending, winemaking, labels', 'Wines – 6 family members', 'The art of tasting',
                'Wine and food matching']
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/pic_eight.jpg'),
            title: 'BIVB',
            desc: 'The Bourgogne Wine Board (BIVB) is a non-profit wine association. Built on the principle of each member having an equal voice, it defends and promotes the unique skills of the professions of winemaking, the négociant trade and vine growing, and the heritage of a shared passion.' +
            '<br>The course helps participants to understand the classifications and terroirs of Bourgogne and how they influence the wines made from different micro-climates.' +
            '<br>Participants who pass the exam will receive an official certificate issued by The Bourgogne Wine Board.',
            content: ['Bourgogne wine history', 'Climate, soils and grape varieties', 'Micro-climates', 'Classification', 'Winemaking',
                'Wine tasting', 'Pairing food with Bourgogne Wines']
        }]
    },
    list: [{
        title: 'CIVB',
        enTitle: 'CIVB',
        items: [{
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/desc_one.jpg'),
            title: 'Trade Customer Training',
            desc: 'To strengthen communication with clients and help them better understand ASC’s products, we provide professional wine trainings upon their requirements, including basic wine knowledge training, wine list training, wine service training, sales skill training and etc.. Meanwhile, ASC Wine Club Courses are offered to clients from all channels and wine lovers. We would choose different topics and taste some typical wines, sharing wine culture with all guests.'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/desc_two.jpg'),
            title: 'Private and Corporate Training Events',
            desc: 'Tailor-made wine courses and related wine events are also available in ASC. We have successfully cooperated with many well-known corporations and business groups on this program. Either we design wine courses with interesting topics for their VIP clients or staff, arousing people’s interest in wines and wine culture; or we help them organize wine dinners or tasting events, sharing the beauty of wine.'
        }]
    }, {
        title: 'CIVB',
        enTitle: 'CIVB',
        items: [{
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/desc_three.jpg'),
            title: 'Internal Staff Trainings',
            desc: 'We periodically organize internal staff trainings on theoretical knowledge, wine tasting, new item introduction, brand presentation, etc., aiming to enhance their wine knowledge and help them better understand the brands and products, which enable them to offer more professional wine recommendation and introduction to clients. In addition, representatives of many wineries cooperating with ASC will also arrange brand training and wine tasting when they visit China.'
        }, {
            img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/education/desc_four.jpg'),
            title: 'Frontline Promoter Trainings',
            desc: 'Frontline promoters play a very important role in hypermarkets and supermarkets, providing professional advice and service for wine consumers. Our trainings help them to make them feel more confident.'
        }]
    }],
    onlineCourse:{
        title: 'ONLINE WINE TRAINING COURSE',
        enTitle: 'ONLINE WINE TRAINING COURSE',
        items:[]
    }
}
