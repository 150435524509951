import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueI18n from 'vue-i18n'
import vuetifyZh from 'vuetify/es5/locale/zh-Hans';
import vuetifyEn from 'vuetify/es5/locale/en';
import en from '@/locales/en'
import zh from '@/locales/zh'
import '@mdi/font/css/materialdesignicons.css'

const messages = {
    'en': Object.assign(en, vuetifyEn),
    'zhHans': Object.assign(zh, vuetifyZh),
};
const lang = localStorage.getItem('asc-locale') || 'zhHans';
Vue.use(Vuetify);
Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: lang,
    messages,
    silentTranslationWarn: true
});

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: false,
        },
        themes: {
            light: {
                primary: '#B78E55',
                secondary: '#000000',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
});

